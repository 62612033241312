import {
  Form,
  Modal,
  Spin,
  notification,
  Row,
  Space,
  Button,
  Select,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { downloadContentResourceMappingRelationship } from "../../services/copywriting";
import { HTTP_STATUS_OK } from "../../constants/config";
import { downloadFile } from "../../util/files";
import Products from "../../services/products";

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  visible: boolean;
}

function ModalForm({ visible, onClose, onSuccess }: ModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState<any[]>([]);
  const [form] = Form.useForm();

  const getChannels = useCallback(async () => {
    try {
      setLoading(true);
      const cls = await Products.getAccountEnabled();
      setLoading(false);
      setChannels(cls);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getChannels();
  }, [getChannels]);

  const getFileName = (headers: any, defaultName: string) => {
    try {
      const tempName = headers["content-disposition"];
      const fileName = tempName.split(";")[1].split("=")[1];
      return fileName ? fileName : defaultName;
    } catch (error) {
      return defaultName;
    }
  };

  const onExportHandle = useCallback(async () => {
    let template;
    let fileName;
    try {
      setProcessing(true);
      const { channelNums = [] } = form.getFieldsValue();
      const res = await downloadContentResourceMappingRelationship(channelNums);
      const { data: fileBlob, status, headers } = res;
      if (HTTP_STATUS_OK === status) {
        // console.log('h->', headers);
        template = fileBlob;
        fileName = getFileName(
          headers,
          "ContentResource-Channel-Attributes.xlsx"
        );
      }
    } catch (error) {
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
    if (!template) {
      notification.error({ message: "Export error" });
      return;
    }
    downloadFile(fileName, template);
    onSuccess();
  }, [onSuccess, form]);

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={<TitleHeader>Export Content Resource Mapping</TitleHeader>}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={500}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={onClose} disabled={processing}>
              Cancel
            </Button>
            <Button
              onClick={onExportHandle}
              disabled={processing}
              type="primary"
            >
              Export
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={processing}>
        <Form form={form}>
          <Form.Item label="Channels" name="channelNums">
            <Select
              mode="multiple"
              loading={loading}
              placeholder="Channel"
              style={{ width: "100%" }}
              filterOption={(input: string, option: any) => {
                return ((option.children ||
                  option.label ||
                  option.value) as string)
                  .toLowerCase()
                  .startsWith(input.toLowerCase());
              }}
              showSearch
            >
              {channels.map((c: any) => (
                <Select.Option key={c.channelNum} value={c.channelNum}>
                  {c.channelName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ModalForm;
