import React from "react";
import { Button, Row, Space, Input, notification, message } from "antd";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from "../../constants/config";
import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import ModalDialog from "../../components/common/ModalDialog";
import { SalesChannelGridWrapper } from "../DetailProduct/styledComponents";
import { loadChannelControlFlags } from "../DetailProduct/helper";
import { getProfilesState } from "../../redux/utils";
import { fetchProfileSettings } from "../../services/channels";
import OverwriteOption from "../DetailProduct/OverwriteOption";
import { batchAssignSalesChannel } from "../../services/products";

let profileSettings: StringKAnyVPair[] = [];

type Props = {
  onClose: Function;
  onSuccess: Function;
  visible: boolean;
  selectedProductRows: any[];
  group: number;
};

const catalogDict: StringKAnyVPair = {
  "0": "No",
  //'1': 'Prepare Catalog',
  //'2': 'Sync Catalog',
  "2": "Prepare Catalog",
  "1": "Sync Catalog",
};

const catalogEditData = [
  { id: 0, label: "No" },
  //{ id: 1, label: 'Prepare Catalog' },
  //{ id: 2, label: 'Sync Catalog' },
  { id: 2, label: "Prepare Catalog" },
  { id: 1, label: "Sync Catalog" },
];

const inventoryDict: StringKAnyVPair = {
  "0": "No",
  "1": "Feed Inventory by Rule",
  "2": "Feed Inventory as Zero",
};

const inventoryEditData = [
  { id: 0, label: "No" },
  { id: 1, label: "Feed Inventory By Rule" },
  { id: 2, label: "Feed Inventory as Zero" },
];

const SalesChannelDialog = (props: Props) => {
  const { useCallback, useState } = React;
  const [gridRef, setGridRef] = useState<any>(null);
  const [inited, setInited] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [channels, setChannels] = useState<StringKAnyVPair[]>([]);
  const [lastSelectedIds, setLastSelectedIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchTimer, setSearchTimer] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState<StringKAnyVPair>({});
  const searchInputRef = React.useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [overwriteChild, setOverwriteChild] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(
      (e) =>
        profileNum > 0 &&
        e.ProfileNum === profileNum &&
        e.SettingCode === "ProductOverwriteChildrenValue"
    )[0];

    if (!ps1) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(
            (e) =>
              profileNum > 0 &&
              e.ProfileNum === profileNum &&
              e.SettingCode === "ProductOverwriteChildrenValue"
          )[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwriteChild(!!allowOverWrite);
    }
  }, []);

  React.useEffect(() => {
    if (props.group !== 4) {
      loadProfileSetting();
    }
  }, [loadProfileSetting, props.group]);

  const loadChannelFlags = useCallback(async () => {
    try {
      setIsLoading(true);
      const cs = await loadChannelControlFlags();

      setIsLoading(false);

      if (Array.isArray(cs)) {
        setChannels(
          cs.map((e) => {
            return {
              chnId: e.ProductCHNLCtrlFlagId,
              channel:
                e.CHNLCtrlFlagDesc || e.CHNLCtrlFlag || e.ProductCHNLCtrlFlagId,
              inventory: e.sendInventory,
              catalog: e.sendContent,
            };
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const cellDOMProps = (cellProps: StringKAnyVPair) => {
    return {
      onClick: () => {
        gridRef.current.startEdit({
          columnId: cellProps.id,
          rowIndex: cellProps.rowIndex,
        });
      },
    };
  };

  const cellSelectorValue = (value: any) => {
    const label = typeof value === "string" ? value : "";

    return (
      <Row align="middle" justify="space-between">
        {label}
        <span className="cell-right-icon">
          <EditOutlined />
        </span>
      </Row>
    );
  };

  const columns = [
    {
      defaultFlex: 1,
      name: "channel",
      header: "Channel",
      headerAlign: "center" as "center",
      showColumnMenuTool: false,
    },
    {
      editor: SelectEditor,
      editorProps: {
        idProperty: "id",
        dataSource: inventoryEditData,
        collapseOnSelect: true,
        clearIcon: null,
      },
      name: "inventory",
      header: "Inventory",
      headerAlign: "center" as "center",
      minWidth: 190,
      render: (data: any) => cellSelectorValue(inventoryDict[data.value]),
      showColumnMenuTool: false,
      cellDOMProps,
    },
    {
      editor: SelectEditor,
      editorProps: {
        idProperty: "id",
        dataSource: catalogEditData,
        collapseOnSelect: true,
        clearIcon: null,
      },
      name: "catalog",
      header: "Catalog",
      headerAlign: "center" as "center",
      minWidth: 170,
      render: (data: any) => cellSelectorValue(catalogDict[data.value]),
      showColumnMenuTool: false,
      cellDOMProps,
    },
  ];

  const dialogTitle = () => {
    return "Bulk Assign Sales Channel";
  };

  const getChannels = () => {
    const chn = searchText.toLowerCase();

    return channels.filter((e) => {
      if (chn) {
        return e.channel.toLowerCase().indexOf(chn) > -1;
      } else {
        return true;
      }
    });
  };

  // eslint-disable-next-line

  const onCloseDialog = () => {
    props.onClose();
  };

  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...channels];

      //console.log('dd-->', data, value, columnId, rowId);
      for (let i = 0; i < data.length; i++) {
        if (data[i].chnId === rowId) {
          if (data[i][columnId] !== value) {
            //console.log(data[i][columnId], value, '<--');
            data[i][columnId] = value;

            if (selectedChannel[rowId]) {
              selectedChannel[rowId][columnId] = value;
              console.log("set");
              break;
            }
          }
        }
      }

      //console.log('d', data, selectedChannel);
      setChannels(data);
      setSelectedChannel({ ...selectedChannel });
    },
    [channels, selectedChannel]
  );

  const onFullscreen = (isFullscreen: boolean) => {
    setIsFullscreen(isFullscreen);
  };

  const onInputSearchText = (evt: any) => {
    if (searchTimer) {
      clearTimeout(searchTimer);
      //setSearchTimer(0);
    }

    const timer = setTimeout(onSearchChannel, 1000);

    setSearchTimer(timer);
  };

  const onSearchChannel = () => {
    const t = searchInputRef.current?.input.value;
    const chn = typeof t === "string" && t.trim() ? t : "";
    //console.log('evt', searchInputRef.current.input.value, chn);

    setSearchText(chn);

    if (searchTimer) {
      setSearchTimer(0);
    }
  };

  const onSelectionChange = useCallback(
    ({ selected }) => {
      // console.log(selected)
      const idList: string[] = Object.keys(selected);

      idList.forEach((k) => {
        let isZero = false;

        if (lastSelectedIds.indexOf(k) < 0) {
          if (selected[k].catalog === 0) {
            //console.log('s', selectedChannel, channels);
            selected[k].catalog = 1;
            isZero = true;
          }
        }

        if (isZero) {
          for (let i = 0; i < channels.length; i++) {
            if (channels[i].chnId === k) {
              channels[i].catalog = 1;
              break;
            }
          }
        }
      });
      setChannels([...channels]);

      if (idList.length > 1) {
        if (idList.length === 2) {
          const temp: any = {};
          temp[idList[1]] = selected[idList[1]];
          setSelectedChannel(temp);
          setLastSelectedIds([idList[1]]);
        } else {
          return;
        }
      } else {
        setSelectedChannel(selected);
        setLastSelectedIds([idList[0]]);
      }
    },
    [channels, lastSelectedIds]
  );

  const saveValues = useCallback(async () => {
    //console.log('sel', selectedChannel);
    // props.onSave(selectedChannel);
    // onCloseDialog();

    try {
      setSaveLoading(true);
      const idList: string[] = Object.keys(selectedChannel);
      if (idList.length === 0) return;
      const temp = selectedChannel[idList[0]];
      const params = {
        ApplyToAllRelevantSubStyleAndSKU: overwriteChild ? 1 : 0,
        ProductList: props.selectedProductRows.map((i) => i.ProductId),
        CHNLCtrlFlagNum: idList[0],
        SendInventory: temp.catalog,
        SendContent: temp.inventory,
      };
      // console.log(params);
      const res = await batchAssignSalesChannel(params);
      setSaveLoading(false);
      if (res) {
        message.success("Save successfully");
        props.onSuccess();
      }
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }

    // console.log(selectedChannel)
    // props.onSuccess();
  }, [selectedChannel, overwriteChild, props]);

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
      loadChannelFlags();
    }
  }, [loadChannelFlags, inited]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton
        footer={null}
        fullscreen
        //okText="Save"
        onClose={onCloseDialog}
        onFullscreen={onFullscreen}
        //onOk={onSaveImage}
        style={{ paddingBottom: 0 }}
        title={dialogTitle()}
        visible={props.visible}
        //width={isChatEnable ? 1260 : 700}
        width={780}
      >
        <Row align="middle" style={{ marginBottom: 10 }}>
          <Space>
            <Input.Search
              allowClear
              onKeyUp={onInputSearchText}
              onSearch={onSearchChannel}
              placeholder="Input search text"
              ref={searchInputRef}
              style={{ width: 400 }}
            />
            {props.group !== 4 && (
              <OverwriteOption
                onChange={setOverwriteChild}
                overwrite={overwriteChild || false}
              />
            )}
          </Space>
        </Row>
        <SalesChannelGridWrapper
          style={{
            height: isFullscreen ? "calc(100vh - 180px)" : 400,
            marginBottom: 12,
          }}
        >
          <DataGrid
            idProperty="chnId"
            checkboxColumn
            loading={isLoading || saveLoading}
            checkboxOnlyRowSelect={true}
            columns={columns}
            dataSource={getChannels()}
            editable={true}
            enableKeyboardNavigation={false}
            onSelectionChange={onSelectionChange}
            onEditComplete={onEditComplete}
            onReady={setGridRef}
            rowHeight={35}
            selected={selectedChannel}
            style={{ height: "100%" }}
          />
        </SalesChannelGridWrapper>
        <Row justify="end">
          <Space>
            <Button onClick={onCloseDialog}>
              <CloseOutlined />
              Cancel
            </Button>
            <Button
              disabled={JSON.stringify(selectedChannel) === "{}"}
              loading={saveLoading}
              onClick={saveValues}
              type="primary"
            >
              <SaveOutlined />
              Save
            </Button>
          </Space>
        </Row>
      </ModalDialog>
    </>
  );
};

export default SalesChannelDialog;
