// eslint-disable-next-line
import axios from 'axios';

import { getProfilesState } from '../redux/utils';
import AcrossTabsChild from './across-tabs/child';
// eslint-disable-next-line
import { fetchAccountPermissions, fetchPortalProfiles, fetchUserPermissions } from '../services/userManagement';
// eslint-disable-next-line
import { randomString } from '.';

export const ENTRANCE_B2C = 1;
export const ENTRANCE_PORTAL = 2;

//const ACT_MSG_LOGIN = 0;
const ACT_MSG_LOGOUT = 1;
const ACT_MSG_PROFILE_REQUEST = 2;
const ACT_MSG_PROFILE_CHANGED = 3;
//const ACT_MSG_PARENT_CONNECTED = 4;
//const ACT_MSG_CLOSE_CHILD = 5;
const ACT_MSG_OPEN_NEW_TAB = 6;

const ACT_TABS_CHILD_STATE_IDLE = -1;
const ACT_TABS_CHILD_STATE_DISCONNECT = 0;
const ACT_TABS_CHILD_STATE_INITED = 2;
const ACT_TABS_CHILD_STATE_READY = 1;

const FETCHING_TOKEN_KEY = 'ss_fetching_digitbridge_token';
//const LOGIN_KEY = 'ss_digitbridge_idt';
const STORE_TYPE = 'local';
const profile = {
  isLogined: false,
  isProfileSelected: false,
  profiles: [],
  selectedIndex: 0,
};
const siteCfg = {entrance: ENTRANCE_B2C};
const store = STORE_TYPE === 'local' ? localStorage : sessionStorage;

/**
 * authState: number
 * Authentication state can be:
 *  -1: default value
 *   0: disconnect
 *   1: ready
 *   2: initialized
 */
let authState = ACT_TABS_CHILD_STATE_IDLE;
let authB2C = null;
let childTab = null;
let newTabRender = null;
let portalProfiles = [];
let rootRender = null;
let token = '';

/**
 * start: Function, start is the callback, which is used to boot the App
 */
const fetchSiteConfig = async (start) => {
  try {
    const res = await axios.get('/site.txt', {
      params: {v: randomString(8)},
      responseType: 'json',
    });

    //console.log('res', res);
    if (res.status === 200) {
      //console.log(res.data);
      if (res.data && typeof res.data === 'object') {
        // eslint-disable-next-line
        for (const k in res.data) siteCfg[k] = res.data[k];
      }
    }
  } catch (e) {
    console.log('Fetched site error:', e);
  } finally {
    start();
  }
};

const onChildTabInitialize = () => {
  console.log('init');
  authState = ACT_TABS_CHILD_STATE_INITED;
  setTimeout(tryEnter, 0);
  //setTimeout(tryFetchToken, 0);
};

const onChildTabReady = () => {
  console.log('ready');
  authState = ACT_TABS_CHILD_STATE_READY;
  //detectEnvironment();
  setTimeout(tryEnterWithoutPortal, 1000);
};

const onParentTabCommunication = (data) => {
  if (authState !== ACT_TABS_CHILD_STATE_INITED) return;

  if (typeof data === 'string') {
    try {
      const msg = JSON.parse(data);

      if (typeof msg === 'object') {
        //console.log('communicate', authState, msg);
        switch (msg.messageType) {
          //case 0:
          case ACT_MSG_PROFILE_REQUEST:
            onRecvLoginMessage(msg.messageContent);
            break;

          case ACT_MSG_PROFILE_CHANGED:
            onRecvLoginStateMessage(msg.messageContent);
            break;

          default:
            break;
        }
      }
    } catch (e) {
      console.log('msg error', data, e);
    }
  }
};

const onParentTabDisconnect = () => {
  console.log('disconnect');
  authState = ACT_TABS_CHILD_STATE_DISCONNECT;
};

const onRecvLoginMessage = (data) => {
  const msg = JSON.parse(data);
  //console.log('lm', msg);
  if (typeof msg === 'object') {
    token = msg.apiSetting.apiAuthToken;
    //store.setItem(LOGIN_KEY, data);
    if (msg.profile && typeof msg.profile === 'object') {
      //console.log(msg.profile);
      // eslint-disable-next-line
      for (const k in msg.profile) profile[k] = msg.profile[k];
    }

    if (typeof rootRender === 'function') rootRender();
  }
};

const onRecvLoginStateMessage = (data) => {
  const msg = JSON.parse(data);

  //console.log('m', msg);
  if (msg && typeof msg === 'object') {
    switch (msg.loginState) {
      case 'profileChanged':
        setTimeout(() => {
          window.location.reload();
        }, 0);
        break;

      default:
        break;
    }
  }
};

const redirectPortal = () => {
  const envMode = process.env.MODE || '';
  // eslint-disable-next-line
  const appId = envMode === 'admin' ? 'ADMIN-DB' : window.location.pathname.includes('/pim') ? 'PIM-DB' : 'CC-DB';
  //console.log(`${process.env.PORTAL_URL}?returnTo=${window.location.origin}`);
  window.location.href = `${process.env.PORTAL_URL}?returnTo=${window.location.href}&appId=${appId}`;
  
};

const requestLogin = () => {
  sendMessageToParent({
    messageType: ACT_MSG_PROFILE_REQUEST,
    messageContent: '',
  });
};

const sendMessageToParent = (m) => {
  if (!childTab) {
    console.log('child tab not ready');
    return;
  }

  if (authState !== ACT_TABS_CHILD_STATE_INITED) {
    console.log('child tab not initialized');
    //window.location.reload();
    return;
  }

  if (m) {
    //const tabInfo = childTab.getTabInfo();
    //m.tabId = tabInfo.name;
    const msg = typeof m === 'object' ? JSON.stringify(m) : m;

    console.log(msg/*, childTab.getTabInfo()*/);
    childTab.sendMessageToParent(msg);
  }
};

const startApp = () => {
  if (siteCfg.entrance === ENTRANCE_PORTAL) {
    if (!childTab) {
      startChildTab();
    } else {
      console.error('authentication instance already exist');
    }
  } else {
    if (window.parent.name) {
      if (newTabRender) newTabRender();
    } else {
      if (authB2C) authB2C(rootRender);
    }
  }
};

const startChildTab = () => {
  if (!childTab) {
    childTab = new AcrossTabsChild({
      onReady: onChildTabReady,
      onInitialize: onChildTabInitialize,
      handshakeExpiryLimit: 4000,
      onParentDisconnect: onParentTabDisconnect,
      onParentCommunication: onParentTabCommunication,
    });
  }
};

const tryEnter = () => {
  if (authState < 2) {
    tryFetchToken();
  } else if (authState === 2) {
    requestLogin();
  } else {
    console.log('auth state', authState);
  }
};

const tryEnterWithoutPortal = () => {
  if (authState === 1) {
    //redirectPortal();
    if (window.parent.name) {
      redirectPortal();
    } else {
      siteCfg.entrance = ENTRANCE_B2C;

      setTimeout(() => {
        if (authB2C) authB2C(rootRender);
      }, 0);
    }
  }
};

const tryFetchToken = () => {
  const fk = store.getItem(FETCHING_TOKEN_KEY);

  //console.log('fk', fk);
  if (fk) {
    requestLogin();
    store.removeItem(FETCHING_TOKEN_KEY);
  } else {
    store.setItem(FETCHING_TOKEN_KEY, '1');
    redirectPortal();
  }
};

export const authenticate = (authB2CHandler, renderRoot, renderNewTab) => {
  authB2C = authB2CHandler;
  newTabRender = renderNewTab;
  rootRender = renderRoot;
  fetchSiteConfig(startApp);
};

export const getPortalProfiles = async () => {
  if (portalProfiles.length > 0) return portalProfiles;

  const res = await fetchPortalProfiles();

  if (Array.isArray(res)) {
    portalProfiles = [...res];
  }

  return portalProfiles;
};

export const getEntrance = () => siteCfg.entrance;

export const getProfile = () => siteCfg.entrance === ENTRANCE_PORTAL ? profile : getProfilesState();

export const getProfileList = () => {
  let p = getProfile();

  if (Array.isArray(p.profiles)) {
    p = p.profiles.map(e => {
      const pe = { ...e };

      pe.email = e.email === undefined ? e.Email : e.email;
      pe.Email = e.Email === undefined ? e.email : e.Email;
      pe.MasterAccountNum = e.MasterAccountNum === undefined ? e.masterAccountNum : e.MasterAccountNum;
      pe.masterAccountNum = e.masterAccountNum === undefined ? e.MasterAccountNum : e.masterAccountNum;
      pe.ProfileNum = e.ProfileNum === undefined ? e.profileNum : e.ProfileNum;
      pe.profileNum = e.profileNum === undefined ? e.ProfileNum : e.profileNum;

      return pe;
    });
    //console.log(getTokenExpiredDate(), (new Date()).getTime() / 1000, '<---');
  }

  return p;
};

export const getSelectedProfile = () => {
  const idx = getSelectedProfileIndex();
  const ps = getProfileList();

  return ps[idx] || null;
};

export const getSelectedProfileIndex = () => {
  const p = getProfile();

  return p.selectedIndex;
};

export const getToken = () => siteCfg.entrance === ENTRANCE_PORTAL ? token : localStorage.getItem('Authorization') || '';

// The returned time unit is seconds
export const getTokenExpiredDate = () => {
  let ret = 0;

  if (siteCfg.entrance === ENTRANCE_B2C) {
    for (let i = 0; i < localStorage.length; i++) {
      const k = localStorage.key(i);

      if (typeof k === 'string' && k.startsWith('{')) {
        try {
          const bck = JSON.parse(localStorage.getItem(k));

          if (bck && bck.expiresIn) {
            //console.log(i, bck.expiresIn, k);
            //console.log(k);
            const expire = typeof bck.expiresIn === 'number' ? bck.expiresIn : Number(bck.expiresIn);

            ret = expire > ret ? expire : ret;
            //ret = expire;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }
  //console.log('-->', ret, (new Date()).getTime() / 1000, (new Date(ret * 1000)));

  return ret;
};

export const getUserPermissions = async (email) => {
  const p = siteCfg.entrance === ENTRANCE_PORTAL ? await fetchAccountPermissions() : await fetchUserPermissions(email);
  const ps = siteCfg.entrance === ENTRANCE_PORTAL ? (p.userPermissions || []) : p;
  //const p = await fetchAccountPermissions();
  //const ps = p.userPermissions || [];

  return ps.map(e => {
    const pe = { ...e };

    pe.PermissionNum = e.PermissionNum === undefined ? e.permissionNum : e.PermissionNum;
    pe.permissionNum = e.permissionNum === undefined ? e.PermissionNum : e.permissionNum;
    pe.PermissionLevel = e.PermissionLevel === undefined ? e.permissionLevel : e.PermissionLevel;
    pe.permissionLevel = e.permissionLevel === undefined ? e.PermissionLevel : e.permissionLevel;

    return pe;
  });
};

export const isPortalActive = () => {
  return siteCfg.entrance === ENTRANCE_PORTAL && authState === ACT_TABS_CHILD_STATE_INITED;
};

export const logoutFromPortal = () => {
  sendMessageToParent({messageType: ACT_MSG_LOGOUT});
};

/**
 * Request the portal to open a new tab with the url.
 *
 * @param {string} appId - The id of the app
 * @param {string} url - The address of the new tab
 */
export const requestPortalOpenNewTab = (appId, url) => {
  sendMessageToParent({
    messageType: ACT_MSG_OPEN_NEW_TAB,
    messageContent: JSON.stringify({ AppName: appId, Url: url }),
  });
};

export const setSelectedProfileIndex = (idx) => {
  profile.selectedIndex = idx;
};

export const switchProfile = (idx) => {
  //console.log('p', profile);
  const ctn = JSON.stringify({'profile': { ...profile, selectedIndex: idx }});

  sendMessageToParent({
    messageType: ACT_MSG_PROFILE_CHANGED,
    messageContent: ctn,
  });
  profile.selectedIndex = idx;
};
