import React from 'react';
import {
  Button,
  Checkbox,
  Row,
  Space,
  Typography,
  Modal,
  message,
} from 'antd';
import styled from 'styled-components';

import ExpandableSearch from '../../components/common/ExpandableSearch';

type Props = {
  columns: StringKAnyVPair[];
  onClose: Function;
  setColumns: Function;
  visible: boolean;
};

const DialogBody = styled.div`
  width: 900px;

  & .body-topbar {
    margin-bottom: 12px;
  }

  & .col-wrapper {
    display: inline-block;
    width: 20%;
  }

  & .col-wrapper .ant-checkbox-wrapper {
    width: 100%;
  }

  & .col-wrapper .ant-checkbox-inner {
    width: 16px !important;
  }

  & .col-wrapper span:nth-child(2) {
    width: calc(100% - 20px);
  }
`;

const BulkEditColumnSelector = (props: Props) => {
  const { useState } = React;
  const [columns, setColumns] = useState<StringKAnyVPair[]>([]);
  const [columnSearchStr, setColumnSearchStr] = useState('');
  const [inited, setInited] = useState(false);
  const [visibleCols, setVisibleCols] = useState<number[]>([]);
  const bodyRef = React.useRef<any>(null);

  const dialogFooter = () => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isVisibleColsChanged()}
            onClick={saveColumns}
            type="primary"
          >
            Ok
          </Button>
        </Space>
      </Row>
    );
  };

  const getDisplayedColumns = (colList: StringKAnyVPair[]) => {
    let cols = [...colList];

    if (columnSearchStr) {
      cols = cols.filter(col => col.header.toLowerCase().indexOf(columnSearchStr.toLowerCase()) > -1);
    }

    if (cols.length < colList.length) setBodyHeight();

    return cols;
  };

  const onSearchColumn = (str: string) => {
    setColumnSearchStr(str);
  };

  const isVisibleColsChanged = () => {
    const cols: number[] = [];

    columns.forEach((col, i) => { if (col.visible !== false) cols.push(i); });

    return JSON.stringify(cols) !== JSON.stringify(visibleCols);
  };

  const onClose = () => {
    props.onClose();
  };

  const checkColumns = (
    cls: StringKAnyVPair[],
    col: StringKAnyVPair,
  ) => {
    const readCols = ['Image', 'Style', 'Substyle', 'SKU'];
    const temp  = cls.filter((i:any)=> readCols.indexOf(i.header) === -1 && i.visible);

    return readCols.indexOf(col.header) < 0 && temp.length >= 5;
  }

  const onColumnVisibleChange = (evt: any, col: StringKAnyVPair) => {
    //console.log('-->', col.header);
    //console.log('evt', evt, col);
    if (evt.target.checked && checkColumns(columns, col)) return message.warning('You can only select up to 5 editable columns.');

    col.visible = evt.target.checked;
    setColumns([...columns]);
  };

  const renderColumnHeaders = (cols: StringKAnyVPair[]) => {
    return cols.map((col, i) => (
      <span className="col-wrapper" key={`${col.name}-${i}`}>
        <Checkbox
          checked={col.visible !== false}
          onChange={evt => onColumnVisibleChange(evt, col)}
        >
          <Typography.Text ellipsis={{ tooltip: col.header }}>
            {col.header}
          </Typography.Text>
        </Checkbox>
      </span>
    ));
  };

  const saveColumns = () => {
    props.columns.forEach((col, i) => {
      col.visible = columns[i].visible;
    });

    props.setColumns([...props.columns]);
    onClose();
  };

  const setBodyHeight = () => {
    if (bodyRef.current) {
      const body = bodyRef.current;

      body.style.height = `${body.offsetHeight}px`;
    }
  };

  React.useEffect(() => {
    if (!inited) {
      const cols: number[] = [];

      setInited(true);
      setColumns(JSON.parse(JSON.stringify(props.columns)));
      props.columns.forEach((col, i) => { if (col.visible !== false) cols.push(i); });
      setVisibleCols(cols);
    }
  }, [inited, props]);

  return (<>
    <Modal
      centered
      footer={dialogFooter()}
      onCancel={onClose}
      title="Select Columns"
      visible={props.visible}
      width={'auto'}
    >
      <DialogBody ref={bodyRef}>
        <Row className="body-topbar">
          <ExpandableSearch
            flatten={true}
            onSearch={onSearchColumn}
            style={{width: 230}}
          />
        </Row>
        {renderColumnHeaders(getDisplayedColumns(columns))}
      </DialogBody>
    </Modal>
  </>);
};

export default BulkEditColumnSelector;
