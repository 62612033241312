import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  // Tooltip,
  // message,
  notification,
} from 'antd';
import {
  // CopyOutlined,
  // eslint-disable-next-line
  // DownOutlined,
  LinkOutlined,
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
// import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
// import theme from '../../assets/styles/theme';
import ContentLayout from '../../components/ContentLayout';
import SiteContent from '../../components/SiteContent';
import CSVLink, { CSVColumns } from '../../components/common/CSVLink';
import Heading from '../../components/common/Heading';
// eslint-disable-next-line
// import LoadingIcon from '../../components/common/LoadingIcon';
// import SpaceCell from '../../components/common/SpaceCell';
// import SearchBar, { SearchField } from '../../components/common/SearchBar';
import Spacer from '../../components/common/Spacer';
import { DataGrid } from '../../components/common/datagrid/DataGrid2';
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from '../../components/common/datagrid/Filter';
import SearchTips from '../../components/common/SearchTips';
import { FormLabel, StyleInputWrapper } from '../../components/common/styledComponents';
//import message from '../../components/common/message';
//import { reportError } from '../../components/common/notification';
import { fetchContentSourceType, fetchViewList } from '../../services/copywriting';
import { isRequestError } from '../../services/http';
import Products, {
  fetchAlternateList,
  fetchSimpleProductGroup,
  fetchSimpleProductList2,
  fetchSimpleProductPartialData,
} from '../../services/products';
import {
  // DATAGRID_CELL_ICON_WIDTH1,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  // DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  CODE_OPTION_COLOR_CONTAINS,
  CODE_OPTION_COLOR_EQUALS,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_CONTAINS,
  CODE_OPTION_SKU_EQUALS,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_CONTAINS,
  CODE_OPTION_STYLE_EQUALS,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_EQUALS,
  CODE_OPTION_UPC_LIST,
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  OPERATION_CONTAIN,
  OPERATION_EQUALS,
  OPERATION_LIST,
  SEARCH_PRODUCT_PARAMS
} from '../../constants/config';
import { Permissions, ViewLevel } from '../../constants/enums/permissions';
//import { useSimpleProductsQuery } from '../../redux/api/products';
import {
  CW_VIEW_ALL,
  CW_VIEW_CUSTOM,
  CW_VIEW_GROUP,
  bundleTypeOptions,
  getBundleTypeLabel,
  renderFilterTag,
} from '../Copywriting';
import ChannelControlFlagSelector from './ChannelControlFlagSelector';
//import ClassificationSelector from './ClassificationSelector';
import {
  getOperationStatusDesc,
  getOperationStatusOptions,
} from '../DetailProduct/BasicAttrEditor';
import { loadStyleVariations } from '../DetailProduct/helper';
import GroupDetail from './GroupDetail';
import LabelsSelector from './LabelsSelector';
import ProductDetailDialog from './ProductDetailDialog';
import SearchFilterPanel from './SearchFilterPanel';
//import SortSelector from './SortSelector';
import { getProfileSettingValue, isDevEnv, onSelectionChange } from '../../util';
import hasPermission from '../../util/permissions';
import { ConditionItem } from './NewCondition';
import { EnumType } from '../../util/enums';
import ERPLinkTableCell from  '../../components/common/ERPLinkTableCell'
import TableFilter from '../../components/common/TableFilter'
import BulkAssignModal from  './BulkAssign'

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

const SKULink = (props: SKULinkProps) => {
  const { product, target = '_self' } = props;
  const pids = product.ProductId.split('/');
  const productId = pids[pids.length - 1] || '-';

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

// eslint-disable-next-line
const NewProductLink: React.FC<{ type: string, productType: number, bundleType: number }> = ({ type, productType, bundleType }) =>
(
  <Link to={`/new-product/${productType}/${bundleType}`}>
    {type}
  </Link>
);

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  &.image-tree-ctn {
    width: 36px;
  }
`;

const SearchCol = styled(Col)`
  width: 100%;

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #D9D9D9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ': ';
  }

  & .loading-wrapper {
    display: 'inline-block';
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    /*width: 768px;*/
    width: 100%;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 430px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      /*width: 820px;*/
      width: 100%;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      /*width: 868px;*/
      width: 100%;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      /*width: 928px;*/
      width: 100%;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      /*width: 1028px;*/
      width: 100%;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      /*width: 1150px;*/
      width: 100%;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

export const isCodeListType = (typ: number) => {
  const cs = [
    CODE_OPTION_SKU_LIST,
    CODE_OPTION_COLOR_LIST,
    CODE_OPTION_STYLE_LIST,
    CODE_OPTION_UPC_LIST,
  ];

  return cs.indexOf(typ) > -1;
};

export const columns = [
  {
    name: 'mediaURL',
    header: 'Image',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 120,
    onRender(cell: any) {
      //console.log('c->', cell);
    },
    render({ value, data }: { value: string, data: Entities.ProductProfile }) {
      const src = value || ImagePlaceholder;
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: 'VariationParentSKU',
    header: 'Style',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // return data.styleCode || data.VariationParentSKU;
        return <ERPLinkTableCell value={data.styleCode || data.VariationParentSKU || ''} type={1} />
      }

      return '';
    },
  },
  {
    //name: 'ProductId',
    name: 'subStyleCode',
    header: 'Substyle',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 160,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        //return data.colorPatternCode || '';
        // return data.subStyleCode || '';
        return <ERPLinkTableCell value={ data.subStyleCode || ''} type={2} />
      }

      return '';
    },
  },
  {
    name: 'SKU',
    header: 'SKU',
    defaultFlex: 1,
    defaultLocked: true,
    //locked: true,
    minWidth: 216,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  { name: 'ProductTitle', header: 'Title', defaultFlex: 2, minWidth: 200, },
  {
    name: 'ProductStatus',
    header: 'Status',
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
  {
    name: 'Brand',
    header: 'Brand',
    defaultFlex: 1,
    minWidth: 150,
  },
  { name: 'Type', header: 'Type', defaultFlex: 1, minWidth: 100, },
  // {
  //   name: 'Classifications',
  //   header: 'Classification',
  //   defaultFlex: 1,
  //   minWidth: 140,
  //   render({ data }: { data: any }) {
  //     if (data && typeof data === 'object') {
  //       return data.classificationName || data.Classifications || '';
  //     }

  //     return '';
  //   },
  // },
  {
    name: 'ChannelControlFlags',
    header: 'Sales Channel',
    defaultFlex: 1,
    minWidth: 190,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        // note can not use Array.isArray to identify the type of channelControlFlags here
        if (data.channelContorlFlags && typeof data.channelContorlFlags === 'object' && data.channelContorlFlags.length > 0) {
          //return Array.isArray(data.channelControlFlags) ? data.channelControlFlags.join(', ') : (data.ChannelControlFlags || '');
          return data.channelContorlFlags.join(', ');
        }
      }

      return '';
    },
  },
  {
    name: 'Labels',
    header: 'Tags',
    defaultFlex: 1,
    minWidth: 130,
    render({ data }: { data: any }) {
      if (data && typeof data === 'object') {
        return Array.isArray(data.labels) ? data.labels.join(', ') : (data.Labels || '');
      }

      return '';
    },
  },
  { name: 'upc', header: 'UPC', defaultFlex: 1, minWidth: 150, },
  // {
  //   name: 'qty',
  //   header: 'Quantity',
  //   defaultFlex: 1,
  //   filterEditor: NumberFilter,
  //   minWidth: 110,
  // },
];

/*
const searchFields: Array<SearchField<Entities.ProductProfile> | string> = [
  'SKU',
  'ProductTitle',
  'Type',
  'Classification',
  'Brand',
  'QtyTotal',
];*/

const csvColumns: CSVColumns = [
  { key: 'styleCode', header: 'Style' },
  { key: 'subStyleCode', header: 'Substyle' },
  { key: 'SKU', header: 'SKU' },
  { key: 'ProductTitle', header: 'Title' },
  { key: 'Brand', header: 'Brand' },
  { key: 'Type', header: 'Type' },
  { key: 'classificationName', header: 'Classification' },
  { key: 'csvChannelControlFlags', header: 'Sales Channel' },
  { key: 'csvLabels', header: 'Labels' },
  { key: 'upc', header: 'UPC' },
  { key: 'qty', header: 'Quantity' },
];

/*const initialProductListing = {
  ProductTotalCount: 0,
  ProductList: [],
};*/

const PageContent: React.FC = () => {
  const {defaultSearchCodeType, defaultSearchGroup} = localStorage.getItem(SEARCH_PRODUCT_PARAMS) ? JSON.parse(localStorage.getItem(SEARCH_PRODUCT_PARAMS) ||'') : {
    defaultSearchCodeType: CODE_OPTION_SKU_CONTAINS,
    defaultSearchGroup: GROUP_OPTION_PRODUCT,
  }
  const { Option } = Select;
  const DEFAULT_GRID_LIMIT = 20;
  const profiles = useSelector((state: any) => state.profiles);
  const [addSettings, setAddSettings] = useState<StringKAnyVPair[]>([]);
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [detailVisible, setDetailVisible] = useState(false);
  const [displayViewInited, setDisplayViewInited] = useState(false);
  // eslint-disable-next-line
  const [displayViewLoading, setDisplayViewLoading] = useState(false);
  const [displayViewOptions, setDisplayViewOptions] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [displayViewSelected, setDisplayViewSelected] = useState<any[]>([]);
  const [displayViewVersion, setDisplayViewVersion] = useState(0);
  const [displayType, setDisplayType] = useState(CW_VIEW_ALL);
  const [excludeChannelFlagVersion, setExcludeChannelFlagVersion] = useState(0);
  const [extraAgeGroup, setExtraAgeGroup] = useState<string[]>([]);
  const [extraAlternateCode, setExtraAlternateCode] = useState<string[]>([]);
  const [extraAlternateOptions, setExtraAlternateOptions] = useState<StringKAnyVPair[]>([]);
  const [extraBundleType, setExtraBundleType] = useState<number>();
  const [extraCategoryCode, setExtraCategoryCode] = useState<string[]>([]);
  const [extraClassCode, setExtraClassCode] = useState<string[]>([]);
  const [extraCountryOfOrigin, setExtraCountryOfOrigin] = useState<string[]>([]);
  const [extraDepartmentCode, setExtraDepartmentCode] = useState<string[]>([]);
  const [extraDivisionCode, setExtraDivisionCode] = useState<string[]>([]);
  const [extraGender, setExtraGender] = useState<string[]>([]);
  const [extraGroupCode, setExtraGroupCode] = useState<string[]>([]);
  const [extraManufacturer, setExtraManufacturer] = useState<string[]>([]);
  const [extraModel, setExtraModel] = useState<string[]>([]);
  const [extraProductYear, setExtraProductYear] = useState('');
  const [extraRemark, setExtraRemark] = useState('');
  const [extraSubClassCode, setExtraSubClassCode] = useState<string[]>([]);
  const [extraSubGroupCode, setExtraSubGroupCode] = useState<string[]>([]);
  const [filteredTempData, setFilteredTempData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<Entities.ProductProfile[]>([]);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>({});
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const [labelVersion, setLabelVersion] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, setSearchCFs] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState('');
  const [searchCodeType, setSearchCodeType] = useState(defaultSearchCodeType);
  const [searchECCFs, setSearchECCFs] = useState<any[]>([]);
  // eslint-disable-next-line
  const [searchMore, setSearchMore] = useState(false);
  const [searchMultiCode, setSearchMultiCode] = useState('');
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(defaultSearchGroup);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchGroups, setSearchGroups] = useState<number[]>([]);
  const [searchGroupsStr, setSearchGroupsStr] = useState<string[]>([]);
  const [searchGroupsVersion, setSearchGroupsVersion] = useState(0);
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  //const [searchSorter, setSearchSorter] = useState<string>();
  const [searchSorter, setSearchSorter] = useState('SKU ASC');
  const [searchStatus, setSearchStatus] = useState<number[]>([1]);
  const [searchTagVersion, setSearchTagVersion] = useState(0);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [selected, setSelected] = React.useState<any>({});
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false);
  // const [selectedRows, setSelectedRows] = useState<{ [key: string]: Entities.ProductProfile } | boolean>({});
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const [totalCount, setTotalCount] = useState(0);
  const codeInputRef = React.useRef<any>(null);
  const codeListInputRef = React.useRef<any>(null);
  const filterState = React.useRef<any>(null);
  const filterHiddenTimer = React.useRef<any>(0);
  const [productListStyle, setProductListStyle] = useState<string>();
  const [conditionList, setConditionList] = useState<ConditionItem[]>([]);
  const [searched, setSearched] = useState<boolean>(false);
  const [tempGroup, setTempGroup] = useState(defaultSearchGroup)

  useEffect(()=>{
    localStorage.setItem(SEARCH_PRODUCT_PARAMS, JSON.stringify({
      defaultSearchCodeType: searchCodeType,
      defaultSearchGroup: searchGroup,
    }))
  },[searchCodeType, searchGroup])

  /*const csvData: Entities.ProductProfile[] = useMemo(() => {
    if (typeof selectedRows !== 'boolean') {
      if (Object.keys(selectedRows).length > 0) {
        return Object.keys(selectedRows).map(k => selectedRows[k]);
      }
    }

    return filteredData;
  }, [selectedRows, filteredData]);*/
  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearExcludeChannelFlags = () => {
    setSearchECCFs([]);
    setExcludeChannelFlagVersion(excludeChannelFlagVersion + 1);
  };

  const clearExtraFilters = () => {
    clearExcludeChannelFlags();
    setExtraAgeGroup([]);
    setExtraAlternateCode([]);
    setExtraBundleType(undefined);
    setExtraCategoryCode([]);
    setExtraClassCode([]);
    setExtraCountryOfOrigin([]);
    setExtraDepartmentCode([]);
    setExtraDivisionCode([]);
    setExtraGender([]);
    setExtraGroupCode([]);
    setExtraManufacturer([]);
    setExtraModel([]);
    setExtraProductYear('');
    setExtraRemark('');
    setExtraSubClassCode([]);
    setExtraSubGroupCode([]);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  const clearSearchBrand = () => {
    setSearchBrand('');
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle('');
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const searchMoreStyle = useMemo(() => {
    return {
      display: searchMore ? '' : 'none',
    };
  }, [searchMore]);

  const csvSource = (data: StringKAnyVPair[]) => {
    const rowData = (d: StringKAnyVPair) => {
      d.csvChannelControlFlags = Array.isArray(d.channelContorlFlags) ? d.channelContorlFlags.join(', ') : '';
      d.csvLabels = Array.isArray(d.labels) ? d.labels.join(', ') : d.labels;

      return d;
    };
    const getRow = (d: StringKAnyVPair) => {
      let rows: StringKAnyVPair[] = [rowData(d)];

      if (Array.isArray(d.nodes)) {
        d.nodes.forEach(e => {
          const ds = getRow(e);

          rows = [...rows, ...ds];
        });
      }

      return rows;
    };
    let ret: StringKAnyVPair[] = [];

    data.forEach(e => {
      const rows = getRow(e);

      ret = [...ret, ...rows];
    });
    //console.log('dd->', ret);

    return ret;
  };

  const searchCodeTypeSelector = () => (
    <Select
      defaultValue={searchCodeType}
      //onBlur={onSearchbarBlur}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230, }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style List</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Substyle Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Substyle Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Substyle List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );
  const filterValue = [
    { name: 'SKU', operator: 'contains', type: GRID_FILTER13, value: '' },
    { name: 'ProductTitle', operator: 'contains', type: GRID_FILTER11, value: '' },
    { name: 'UPC', operator: 'eq', type: GRID_FILTER12, value: '' },
    { name: 'QtyTotal', operator: 'gte', type: GRID_FILTER21, value: null },
  ];
  const gridFilterTypes = Object.assign({
    styleCodeFilter: {
      type: 'string',
      emptyValue: '',
      operators: [
        { name: 'SKU Contains', fn: () => true },
        { name: 'SKU Equals', fn: () => true },
      ],
    },
  }, filterTypes);

  const dataSource = async (): Promise<{ data: any[], count: number; }> => {
    return {
      data: filteredTempData,
      count: totalCount,
    };
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  const excludeSalesChannel = () => {
    return (
      <div className="filter-form-cell">
        <FormLabel>Exclude Sales Channel</FormLabel>
        <ChannelControlFlagSelector
          channelFlagVersion={excludeChannelFlagVersion}
          onChange={onSelectExcludeCCFs}
        />
      </div>
    );
  };

  const fetchAddSettings = async () => {
    try {
      const settings = await Products.fetchProductCreationSetting();

      //console.log('s', settings);
      if (Array.isArray(settings)) setAddSettings(settings);
    } catch (e) {
      notification.error({
        message: `Fetch Add Setting error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (res && typeof res === 'object' && Array.isArray(res.LabelList) && res.LabelList.length > 0) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const groupSubStyle = (productList: any) => {
    const substyles: any[] = [];
    const products: any[] = [];
    if (_.isArray(productList)) {
      // group substyle and children together
      productList.forEach((item) => {
        if (item.ProductType === 1) {
          products.push(item);
        } else {
          substyles.push(item);
        }
      });
      if (products.length === substyles.length) return productList;
      products.forEach((item) => {
        const {
          subStyleCode,
        } = item;
        const parent = substyles.find((item) => item.SKU === subStyleCode);
        if (parent) {
          parent.nodes = _.isArray(parent.nodes) ? [...parent.nodes, item] : [item];
        } else {
          substyles.push(item);
        }
      });
      return substyles;
    }
    return productList;
  };

  // const fetchChildrenPartialData = async (
  //   node: StringKAnyVPair,
  //   ProductList: Entities.ProductProfile[],
  // ) => {
  //   await fetchProductParticalData(ProductList);
  //   // handle substyle-sku situation
  //   node.nodes = groupSubStyle(ProductList);
  //   //console.log('fd', filteredData);
  //   updateGridRow(node as Entities.ProductProfile);
  // };

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    if (!productListStyle) {
      await getProductListStyle();
    }
    const { skip, top } = options;
    setSelected({});
    setIsFetching(true)

    try {
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList2(
        typeof skip === 'number' && skip >= 0 ? skip : pageSkip,
        typeof top === 'number' && top >= 0 ? top : pageTop,
        getSearchOptions(),
      );

      if (Array.isArray(ProductList)) {
        if (searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR) {
          ProductList.forEach(e => {
            if (['Product', 'Bundle'].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        // setSearchTreeMode(isSearchGroupMode(searchCodeType));
        setSearchTreeMode(searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR)
        setTempGroup(searchGroup)

        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      //message.error(`Fetch products error: ${e}`);
      notification.error({
        message: `Fetch products error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
      console.log('Fetch products error:', e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map(e => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        //console.log('d->', data.headers, products);
        data.forEach(e => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      if (!isRequestError(e)) {
        notification.error({
          message: `Fetch partial error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
      // console.error('Fetch partial error:', e, e.isAxiosError);
    } finally {
      // setIsFetching(false);
    }
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode.split('\n')
          .map(e => e.trim())
          .filter(e => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: 'ProductStatus',
      collectionFilterValues: searchStatus,
      op: 7,
    });

    if (searchCCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchCCFs,
        op: OPERATION_EQUALS,
      });
    }

    if (searchECCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchECCFs,
        op: 7,
      });
    }

    if (searchLabels.length > 0) {
      ret.push({
        filterName: "Labels",
        //filterValue: searchLabels,
        collectionFilterValues: searchLabels,
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      ret.push({
        filterName: "Brand",
        //filterValue: searchBrand.split(','),
        collectionFilterValues: searchBrand.split(','),
        op: OPERATION_CONTAIN,
      });
    }

    if ((searchTags || []).length > 0) {
      ret.push({
        filterName: "Tag",
        collectionFilterValues: searchTags,
        op: OPERATION_EQUALS,
      });
    }

    if (extraAgeGroup.length > 0) {
      ret.push({
        filterName: 'AgeGroup',
        collectionFilterValues: extraAgeGroup,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraAlternateCode.length > 0) {
      ret.push({
        filterName: 'AlternateCode',
        collectionFilterValues: extraAlternateCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCategoryCode.length > 0) {
      ret.push({
        filterName: 'CategoryCode',
        collectionFilterValues: extraCategoryCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraClassCode.length > 0) {
      ret.push({
        filterName: 'ClassCode',
        collectionFilterValues: extraClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCountryOfOrigin.length > 0) {
      ret.push({
        filterName: 'CountryOfOrigin',
        collectionFilterValues: extraCountryOfOrigin,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDepartmentCode.length > 0) {
      ret.push({
        filterName: 'DepartmentCode',
        collectionFilterValues: extraDepartmentCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDivisionCode.length > 0) {
      ret.push({
        filterName: 'DivisionCode',
        collectionFilterValues: extraDivisionCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGender.length > 0) {
      ret.push({
        filterName: 'Gender',
        collectionFilterValues: extraGender,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGroupCode.length > 0) {
      ret.push({
        filterName: 'GroupCode',
        collectionFilterValues: extraGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraManufacturer.length > 0) {
      ret.push({
        filterName: 'Manufacturer',
        collectionFilterValues: extraManufacturer,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraModel.length > 0) {
      ret.push({
        filterName: 'Model',
        collectionFilterValues: extraModel,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraProductYear) {
      ret.push({
        filterName: 'ProductYear',
        collectionFilterValues: [extraProductYear],
        op: OPERATION_CONTAIN,
      });
    }

    /*if (extraRemark) {
      ret.push({
        filterName: 'Remark',
        collectionFilterValues: [extraRemark],
        op: OPERATION_CONTAIN,
      });
    }*/

    if (extraSubClassCode.length > 0) {
      ret.push({
        filterName: 'SubClassCode',
        collectionFilterValues: extraSubClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraSubGroupCode.length > 0) {
      ret.push({
        filterName: 'SubGroupCode',
        collectionFilterValues: extraSubGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        //return 'VariationParentSKU';
        return 'ColorPatternCode';

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return 'SKU';

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return 'StyleCode';

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return 'UPC';
    }
  };

  // eslint-disable-next-line
  const getGroupList = async () => {
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = '',
      } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        notification.error({
          message: resMsg || 'No group list found',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  // const isCommonGroupOption = (code: number) => {
  //   return isColorCode(code) || isStyleCode(code);
  // };

  // const isSearchGroupMode = (code: number) => {
  //   // return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  //   return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_PRODUCT;
  // };

  const getSearchGrouper = useCallback(() => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = '';
    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = 'ColorPatternCode';
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = 'Product';
        break;

      case GROUP_OPTION_STYLE:
        groupName = 'StyleCode';
        break;
      default:
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
    // eslint-disable-next-line
  }, [searchGroup, searchCodeType]);

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];
    /*let searchCodeValue = searchCodeType === CODE_OPTION_SKU_LIST 
      ? searchCode.split('\n').map((item) => item.trim()).filter(item => item)
      : searchCode;*/
    if (searchCodeType !== CODE_OPTION_SKU_LIST && searchCodeType !== CODE_OPTION_COLOR_LIST && searchCodeType !== CODE_OPTION_STYLE_LIST && searchCodeType !== CODE_OPTION_UPC_LIST) {
      filters.push({
        'filterName': getFilterNameBySearchCodeType(),
        //'filterValue': searchCodeValue,
        'filterValue': searchCode,
        'op': getOperateCodeBySearchCodeType(),
      });
    }

    if (searchTitle) {
      filters.push({
        'filterName': 'ProductTitle',
        'filterValue': searchTitle,
        'op': OPERATION_CONTAIN,
      });
    }

    if (searchBrand) {
      filters.push({
        'filterName': 'Brand',
        'filterValue': searchBrand,
        'op': OPERATION_CONTAIN,
      });
    }

    if (searchCCFs.length > 0) {
      filters.push({
        'filterName': 'ChannelControlFlag',
        'filterValue': searchCCFs.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    if (searchCFs.length > 0) {
      filters.push({
        'filterName': 'Classification',
        'filterValue': searchCFs.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    if ((searchGroups || []).length > 0) {
      filters.push({
        filterName: "ContentSourceGroup",
        filterValue: searchGroups.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (typeof extraBundleType === 'number') {
      filters.push({
        filterName: 'BundleType',
        filterValue: extraBundleType,
        op: OPERATION_EQUALS,
      });
    }

    if (extraRemark) {
      filters.push({
        filterName: 'Remark',
        filterValue: extraRemark,
        op: OPERATION_CONTAIN,
        //op: OPERATION_EQUALS,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        'filterName': 'Labels',
        'filterValue': searchLabels.join('|'),
        'op': OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      //queryGrouper: getCollectionFilters().length > 0 ? null : getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
      queryAttributeFilters: getAttributeFilters(),
    };
  };

  const getAttributeFilters = () => {
    let ret: {
      filterNum: string;
      op: 1 | 2 | 3 | 4 | 5;   // 1:= 2:like 3:> 4:< 5 <>
      filterValue: string;
      filterType: 1, // 目前固定传1
      filterFlag: 0, // 目前固定传0
    }[] = [];

    console.log('conditionList = ', conditionList);

    if (conditionList.length > 0) {
      conditionList.forEach(item => {
        ret.push({
          filterNum: item.attribute.AttributeNum,
          op: item.searchType,
          filterValue: item.value,
          filterType: 1,
          filterFlag: 0
        })
      })
    }
    return ret;
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(' ');

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef: any[] = [];

    for (let i = 0; i < columns.length; i++) {
      if (productListStyle === '2') {
        if (['Style', 'Substyle'].includes(columns[i].header)) {
          continue;
        }
      }
      if (columns[i].header === 'Image') {
        columns[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || ImagePlaceholder;
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer key={data.ProductId} className={isTreeNode ? 'image-tree-ctn' : ''}>
              <Image width={28} height={28} preview={!!value} src={src} />
            </ImageContainer>
          );
        };
        colDef.push(columns[i]);
        continue;
      } else if (columns[i].header === 'SKU') {
        columns[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split('/');
          const productId = pids[pids.length - 1] || '-';

          /*return (<Button type="link" onClick={() => openProductDetailDialog(`${productId}`, data)}>
            {data.SKU}
          </Button>);*/
          // return (
          //   <SpaceCell
          //     erpUrl={`${process.env.ERP_LINK}Inventory/InventoryManager?sku=${data.SKU}`}
          //     icon={
          //       <Tooltip
          //         placement="top"
          //         title="Copy"
          //         trigger={['hover', 'click']}
          //       >
          //         <CopyToClipboard
          //           text={data.SKU || ''}
          //           onCopy={() => notification.success({ message: `"${data.SKU}" has been copied to clipboard`, duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION, })}
          //         >
          //           <CopyOutlined
          //             style={{ color: theme['@info-color'] }}
          //           />
          //         </CopyToClipboard>
          //       </Tooltip>
          //     }
          //     iconWidth={DATAGRID_CELL_ICON_WIDTH1 * 2}
          //     onTextClick={() => openProductDetailDialog(`${productId}`, data)}
          //     text={data.SKU}
          //     textIsButton
          //   />
          // );
          return (<ERPLinkTableCell value={data.SKU} type={3} onClick={()=>  openProductDetailDialog(`${productId}`, data)} />)
        }
        colDef.push(columns[i]);
        continue;
      }
      colDef.push(columns[i]);
    }

    return colDef;
  };

  const handleSearchProducts = () => {
    if (conditionList.filter(line => !line.attribute || !line.value).length > 0) {
      Modal.error({
        title: 'Please complete or delete the advanced filter!',
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk: () => {
          onSearchbarFocus();
        }
      });
      return;
    }
    setPageSkip(0);
    fetchProductList({ skip: 0 });
    setSearched(true);
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  // const isColorCode = (code: number) => {
  //   return [
  //     CODE_OPTION_COLOR_CONTAINS,
  //     CODE_OPTION_COLOR_EQUALS,
  //     CODE_OPTION_COLOR_LIST,
  //   ].indexOf(code) > -1;
  // };


  // const isSKUCode = (code: number) => {
  //   return [
  //     CODE_OPTION_SKU_CONTAINS,
  //     CODE_OPTION_SKU_EQUALS,
  //   ].indexOf(code) > -1;
  // };

  // const isStyleCode = (code: number) => {
  //   return [
  //     CODE_OPTION_STYLE_CONTAINS,
  //     CODE_OPTION_STYLE_EQUALS,
  //     CODE_OPTION_STYLE_LIST,
  //   ].indexOf(code) > -1;
  // };

  const loadAlternateList = async () => {
    const res = await fetchAlternateList();

    console.log('res ->', res);
    if (Array.isArray(res)) {
      setExtraAlternateOptions(res.map(e => ({
        value: e.code,
        label: e.code,
      })));
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    const sv = await loadStyleVariations();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    loadAlternateList();
  };

  const loadNextLevelProducts = async (data: any) => {
    const { node } = data;
    let ret: any = null;

    // if (node.nodes) return;
    //setIsFetching(true);
    try {
      let { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        if (ProductList.length > 0) {
          await fetchProductParticalData(ProductList);
          //await fetchProductParticalData(ProductList);
          ProductList = groupSubStyle(ProductList);
          node.nodes = ProductList;
          updateGridRow(node);
          //updateGridRow(node);
        }

        ret = ProductList;
      }
    } catch (e) {
      //message.error(`Fetch children nodes error: ${e}`);
      notification.error({
        message: `Fetch children nodes error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
      console.log('Fetch children nodes error:', e);
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const loadDisplayViewOptions = async () => {
    setDisplayViewLoading(true);

    try {
      const opts = await fetchViewList();
      //console.log('opts', opts);
      if (opts) {
        setDisplayViewOptions(
          opts.map((e: StringKAnyVPair) => ({
            label: e.name,
            value: e.contentResourceViewNum,
          }))
        );
      }
    } catch(e) {
      notification.error({
        message: `Fetch content resource view list error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setDisplayViewLoading(false);
    }
  };

  // eslint-disable-next-line
  const onDisplayTypeChange = (val: number) => {
    setDisplayType(val);
    setDisplayViewSelected([]);
    setDisplayViewVersion(displayViewVersion + 1);

    if (val === CW_VIEW_CUSTOM && !displayViewInited) {
      loadDisplayViewOptions();
      setDisplayViewInited(true);
    }
  };

  // eslint-disable-next-line
  const onDisplayValueChange = (val: any) => {
    if (Array.isArray(val)) setDisplayViewSelected(val);
    else setDisplayViewSelected([val]);
  };

  const onExtraFilterFieldChange = (
    field: string,
    val: number | string | string[],
  ) => {
    switch(field) {
      case 'agegroup':
        setExtraAgeGroup(val as string[]);
        break;

      case 'alternate':
        setExtraAlternateCode(val as string[]);
        break;

      case 'bundletype':
        setExtraBundleType(val as number);
        break;

      case 'categorycode':
        setExtraCategoryCode(val as string[]);
        break;

      case 'classcode':
        setExtraClassCode(val as string[]);
        break;

      case 'countryoforigin':
        setExtraCountryOfOrigin(val as string[]);
        break;

      case 'departmentcode':
        setExtraDepartmentCode(val as string[]);
        break;

      case 'divisioncode':
        setExtraDivisionCode(val as string[]);
        break;

      case 'gender':
        setExtraGender(val as string[]);
        break;

      case 'groupcode':
        setExtraGroupCode(val as string[]);
        break;

      case 'manufacturer':
        setExtraManufacturer(val as string[]);
        break;

      case 'model':
        setExtraModel(val as string[]);
        break;

      case 'productyear':
        setExtraProductYear(val as string);
        break;

      case 'remark':
        setExtraRemark(val as string);
        break;

      case 'subclasscode':
        setExtraSubClassCode(val as string[]);
        break;

      case 'subgroupcode':
        setExtraSubGroupCode(val as string[]);
        break;

      default:
        break;
    }
  };

  const onFilterValueChange = (val: any) => {
    console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    fetchProductList({ top: limit });
    setPageTop(limit);
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };

  // eslint-disable-next-line
  const onSearchbarBlur = () => {
    setTimeout(() => {
      const fs = filterState.current;
      console.log('fstate', filterState);
      if (fs) {
        if (fs.timeStamp) {
          const t = (new Date()).getTime();

          console.log('tt', t - fs.timeStamp);
          if (t - fs.timeStamp > 400) {
            setFilterPanelVisible(false);
            //filterState.current = null;
          }
        } else {
          setFilterPanelVisible(false);
        }
      } else {
        setFilterPanelVisible(false);
      }
    }, 20);
  };

  const onSearchbarFocus = () => {
    let ctn = getCodeInputWrapperDom();

    if (ctn) {
      ctn = ctn.parentNode.parentNode.parentNode.parentNode.parentNode;
      const paddingLeft = 14;
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      //setFilterPanelOutline({ x: rect.x, y: rect.y + rect.height, width: rect.width });
      setFilterPanelOutline({
        x: rect.x - paddingLeft,
        y: rect.y + rect.height,
        width: rect.width + paddingLeft * 2,
      });

      if (!filterPanelVisible) {
        showSearchFilterPanel();
      }
    }
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    setSearchCCFs(values);
  };

  // eslint-disable-next-line
  const onSelectClassifications = (values: any[]) => {
    setSearchCFs(values);
  };

  const onSelectGroups = (groups: number[], option: any) => {
    setSearchGroups(groups);
    setSearchGroupsStr(option ? option.map((i: any) => i.label) : []);
  };

  const onSelectLabels = (values: any[]) => {
    setSearchLabels(values);
  };

  const onSelectTags = (value: string) => {
    let str: string = value
      .trim()
      .replace(/[\r\n]/g, ",")
      .replace(/,+/g, ",")
      .replace("/,$/gi", "");
    const tags = str.split(",");
    setSearchTags(tags.filter((item) => item));
  };

  /*const onSelectSortType = (value: any) => {
    setSearchSorter(value);
  };*/

  const onSearchBrandChange = (evt: any) => {
    const value = evt.target.value as string;

    setTimeout(() => setSearchBrand(value), 0);
  };

  const onSearchCodeChange = (evt: any) => {
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  /*const onSearchCodeListChange = (evt: any) => {
    const value = evt.target.value as string;

    const str = value.replace(/\t/g, '\n');

    setSearchMultiCode(str);
  };*/

  /*const onSearchCodeListKeyDown = (evt: any) => {
    if (evt.keyCode === 9) {
      if (searchMultiCode.trim()) {
        setSearchMultiCode(searchMultiCode.trim() + '\n');
      }

      evt.preventDefault();
    }

    evt.stopPropagation();
  };*/

  /*const onSearchStatusChange = (st: number[]) => {
    setSearchStatus(st);
  };*/

  const onSearchTitleChange = (evt: any) => {
    //setSearchTitle(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchTitle(value), 0);
  };

  const onSelectExcludeCCFs = (values: any[]) => {
    setSearchECCFs(values);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (value === CODE_OPTION_SKU_LIST || value === CODE_OPTION_COLOR_LIST || value === CODE_OPTION_STYLE_LIST || value === CODE_OPTION_UPC_LIST) {
      setTimeout(() => {
        setSearchMultiCode('');
        codeListInputRef?.current?.focus();
        codeInputRef.current.setValue('');
        setSearchCode('');
      }, 0);
    } else {
      setTimeout(() => {
        codeInputRef?.current?.select();
        codeInputRef?.current?.focus();
      }, 0);
    }

    //showSearchFilterPanel();
  };

  // const onSelectionChange = useCallback(({ selected }) => {
  //   setSelected(selected);
  // }, []);

  const onSkipChange = (skip: number) => {
    fetchProductList({ skip });
    setPageSkip(skip);
  };

  const onSortChange = async (info: any) => {
  };

  // eslint-disable-next-line
  const actions = useMemo(() => {
    if (!hasPermission(profiles.permissions, Permissions.MANAGE_PRODUCT_ELEMENTS, ViewLevel.VIEW)) {
      return undefined;
    }

    const enableStyle = addSettings.length > 0 && !addSettings.every(e => e.GenerateStyleCode === 0);
    const enableSubStyle = addSettings.length > 0 && !addSettings.every(e => e.GenerateSubStyleCode === 0);

    return (
      <Dropdown
        overlay={(
          <Menu>
            {/*<Menu.Item>
              <NewProductLink type="Product" productType={0} bundleType={0} />
            </Menu.Item>*/}
            {enableStyle && <Menu.Item>
              <Link to="/edit-style/style">
                Style
              </Link>
            </Menu.Item>}
            {enableSubStyle && <Menu.Item>
              <Link to="/edit-style/sub">
                Sub Style
              </Link>
            </Menu.Item>}
            <Menu.Item>
              <Link to="/edit-style/product">
                Product
              </Link>
            </Menu.Item>
            {/*<Menu.Item>
              <NewProductLink type="Bundle" productType={0} bundleType={1} />
            </Menu.Item>*/}
          </Menu>
        )}
        placement="bottomCenter"
        arrow
      >
        <Button type="primary">
          <PlusOutlined />
          Add
        </Button>
      </Dropdown>
    );
  }, [addSettings, profiles]);

  const openProductDetailDialog = (productId: string, product: StringKAnyVPair) => {
    setDetailVisible(true);
    setCurrentProduct(product);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    return <GroupDetail row={data} />;
  };

  const searchPanelFilters = () => {
    return [
      <>
        <FormLabel>Bundle Type</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('bundletype', val as number)}
          options={bundleTypeOptions}
          value={extraBundleType}
        />
      </>,
      <>
        <FormLabel>Category</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('categorycode', val)}
          options={searchStyleVariationOptions('categoryCode')}
          mode="multiple"
          value={extraCategoryCode}
        />
      </>,
      <>
        <FormLabel>Subcategory</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subclasscode', val)}
          options={searchStyleVariationOptions('subclassCode')}
          mode="multiple"
          value={extraSubClassCode}
        />
      </>,
      <>
        <FormLabel>Class</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('classcode', val)}
          options={searchStyleVariationOptions('classCode')}
          mode="multiple"
          value={extraClassCode}
        />
      </>,
      <>
        <FormLabel>Group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('groupcode', val)}
          options={searchStyleVariationOptions('groupCode')}
          mode="multiple"
          value={extraGroupCode}
        />
      </>,
      <>
        <FormLabel>Subgroup</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subgroupcode', val)}
          options={searchStyleVariationOptions('subgroupCode')}
          mode="multiple"
          value={extraSubGroupCode}
        />
      </>,
      <>
        <FormLabel>Division</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('divisioncode', val)}
          options={searchStyleVariationOptions('divisionCode')}
          mode="multiple"
          value={extraDivisionCode}
        />
      </>,
      <>
        <FormLabel>Department</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('departmentcode', val)}
          options={searchStyleVariationOptions('departmentCode')}
          mode="multiple"
          value={extraDepartmentCode}
        />
      </>,
      <>
        <FormLabel>Manufacturer</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('manufacturer', val)}
          options={searchStyleVariationOptions('manufacturer')}
          mode="multiple"
          value={extraManufacturer}
        />
      </>,
      <>
        <FormLabel>Model</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('model', val)}
          options={searchStyleVariationOptions('model')}
          mode="multiple"
          value={extraModel}
        />
      </>,
      <>
        <FormLabel>Alternate</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('alternate', val)}
          options={extraAlternateOptions as any[]}
          mode="multiple"
          value={extraAlternateCode}
        />
      </>,
      <>
        <FormLabel>Remark</FormLabel>
        <Input
          allowClear
          onChange={(evt) => onExtraFilterFieldChange('remark', evt.target.value)}
          value={extraRemark}
        />
      </>,
      <>
        <FormLabel>Product Year</FormLabel>
        <Input
          allowClear
          //onChange={(val) => onExtraFilterFieldChange('productyear', val)}
          onChange={(evt) => onExtraFilterFieldChange('productyear', evt.target.value)}
          //options={[{value: '2022'}, {value: '2023'}, {value: '2024'}]}
          value={extraProductYear}
        />
      </>,
      <>
        <FormLabel>Gender</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('gender', val)}
          options={searchStyleVariationOptions('gender')}
          mode="multiple"
          value={extraGender}
        />
      </>,
      <>
        <FormLabel>Age group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('agegroup', val)}
          options={searchStyleVariationOptions('ageGroup')}
          mode="multiple"
          value={extraAgeGroup}
        />
      </>,
      <>
        <FormLabel>Country of Origin</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('countryoforigin', val)}
          options={searchStyleVariationOptions('countryOfOrigin')}
          mode="multiple"
          value={extraCountryOfOrigin}
        />
      </>,
    ];
  };

  // eslint-disable-next-line
  const searchDisplayCol1Options = () => {
    return [
      {label: 'All', value: CW_VIEW_ALL},
      {label: 'Custom view', value: CW_VIEW_CUSTOM},
      {label: 'Group', value: CW_VIEW_GROUP},
    ];
  };

  // eslint-disable-next-line
  const searchDisplayCol2Options = () => {
    if (displayType === CW_VIEW_CUSTOM) return displayViewOptions;

    if (displayType === CW_VIEW_GROUP) {
      return groupList.map(g => ({
        label: g.enumName,
        value: g.enumValue,
      })) as any;
    }

    return [];
  };

  const searchStyleVariationOptions = (k: string) => {
    let ret: any[] = [];

    if (Array.isArray(styleVariation[k])) {
      ret = styleVariation[k].map((c: StringKAnyVPair) => ({
        //label: c.description || c.code,
        label: c.code,
        value: c.code,
      }));
    }

    return ret;
  };

  const showSearchConditionTags = () => {
    const eccfLabels: string[] = ccfList
      .filter((c) => searchECCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const ccfLabels: string[] = ccfList
      .filter(c => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map(c => c.CHNLCtrlFlag);
    const labels: string[] = labelList.filter(l => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map(l => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter(e => searchStatus.indexOf(e.code) > -1)
      .map(e => e.description);

    const attrSearchTypeEnum: EnumType = { 1: 'equals', 2: 'contains', 5: `doesn't contain` };

    return (
      <Space>
        <TableFilter columns={gridColumns(searchTreeMode)} dataSource={filteredData} setFilteredData={setFilteredTempData}   />
        {searchTitle.trim() && (
          <Tag
            closable
            onClose={clearSearchTitle}
          >
            <span className="field-label">Title</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag
            closable
            onClose={() => setSearchStatus([])}
          >
            <span className="field-label">Exclude Status</span>
            {statusLabels.join(', ')}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(', ')}
          </Tag>
        )}
        {eccfLabels.length > 0 && renderFilterTag(
          'Exclude Sales Channel',
          eccfLabels,
          clearExcludeChannelFlags,
        )}
        {searchBrand.trim() && (
          <Tag
            closable
            onClose={clearSearchBrand}
          >
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Tags</span>
            {labels.join(', ')}
          </Tag>
        )}
        {searchGroupsStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchGroups([]);
              setSearchGroupsStr([]);
              setSearchGroupsVersion(searchGroupsVersion + 1);
            }}
          >
            <span className="field-label">Group</span>
            {searchGroupsStr.join(", ")}
          </Tag>
        )}
        {searchTags.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchTags([]);
              setSearchTagVersion(searchTagVersion + 1);
              // setSearchTagsStr('')
            }}
          >
            <span className="field-label">Tag</span>
            {searchTags.join(", ")}
          </Tag>
        )}
        {typeof extraBundleType === 'number' && renderFilterTag(
          'Bundle Type',
          getBundleTypeLabel(extraBundleType),
          () => setExtraBundleType(undefined),
        )}
        {extraCategoryCode.length > 0 && renderFilterTag(
          'Category',
          extraCategoryCode,
          () => setExtraCategoryCode([]),
        )}
        {extraSubClassCode.length > 0 && renderFilterTag(
          'Subcategory',
          extraSubClassCode,
          () => setExtraSubClassCode([]),
        )}
        {extraClassCode.length > 0 && renderFilterTag(
          'Class',
          extraClassCode,
          () => setExtraClassCode([]),
        )}
        {extraGroupCode.length > 0 && renderFilterTag(
          'Group',
          extraGroupCode,
          () => setExtraGroupCode([]),
        )}
        {extraSubGroupCode.length > 0 && renderFilterTag(
          'Subgroup',
          extraSubGroupCode,
          () => setExtraSubGroupCode([]),
        )}
        {extraDivisionCode.length > 0 && renderFilterTag(
          'Division',
          extraDivisionCode,
          () => setExtraDivisionCode([]),
        )}
        {extraDepartmentCode.length > 0 && renderFilterTag(
          'Department',
          extraDepartmentCode,
          () => setExtraDepartmentCode([]),
        )}
        {extraManufacturer.length > 0 && renderFilterTag(
          'Manufacturer',
          extraManufacturer,
          () => setExtraManufacturer([]),
        )}
        {extraModel.length > 0 && renderFilterTag(
          'Model',
          extraModel,
          () => setExtraModel([]),
        )}
        {extraAlternateCode.length > 0 && renderFilterTag(
          'Alternate',
          extraAlternateCode,
          () => setExtraAlternateCode([]),
        )}
        {extraRemark.trim() && renderFilterTag(
          'Remark',
          extraRemark,
          () => setExtraRemark(''),
        )}
        {extraProductYear.trim() && renderFilterTag(
          'Product Year',
          extraProductYear,
          () => setExtraProductYear(''),
        )}
        {extraGender.length > 0 && renderFilterTag(
          'Gender',
          extraGender,
          () => setExtraGender([]),
        )}
        {extraAgeGroup.length > 0 && renderFilterTag(
          'Age Group',
          extraAgeGroup,
          () => setExtraAgeGroup([]),
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
        {(conditionList.filter(item => item.attribute && item.value)).map(i => {
          return (
            <Tag>
              <span className="field-label">{`${i.attribute.AttributeName} ${attrSearchTypeEnum[i.searchType]}`}</span>
              {i.value}
            </Tag>
          )
        })}
      </Space>
    );
  };

  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: (new Date()).getTime(),
    };
    setFilterPanelVisible(true);
  };

  /*const toggleSearchMore = () => {
    const more = !searchMore;

    setSearchMore(more);
    // clear some state
  };*/

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  const getProductListStyle = async () => {
    const value = await getProfileSettingValue('ProductListStyle', '0');
    setProductListStyle(value);
  }

  useEffect(() => {
    if (!inited) {
      // fetchProductList();
      fetchAddSettings();
      fetchChannelControlFlags();
      fetchLabels();
      getGroupList();
      loadInitialData();
      setInited(true);
      //console.log('load -->');
      //reportError({error: 'Test error message'});
    }
  }, [
    fetchProductList,
    getGroupList,
    inited,
    loadInitialData,
  ]);

  return (<>
    <ContentLayout>
      <Heading
        title="Product Search"
        actions={isDevEnv() ? actions : null}
      />
      <Spacer />
      <SiteContent flexGrow noPadding transparent>
        <Row className="content-section" justify="space-between">
          {/*<SearchCol xs={24} lg={12}>*/}
          <SearchCol>
            {/*<SearchBar
              data={data.ProductList}
              fields={searchFields}
              reference="SKU"
              onResult={setFilteredData}
            />*/}
            <Space direction="vertical" style={{ width: '100%' }}>
              <Row className="search-element-area">
                <Row>
                  {
                    productListStyle !== '2' && (
                      <Row align="middle" className="display-category-field">
                        <span className="field-label" style={{ paddingRight: 8 }}>Display</span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style
                          </Radio>
                          {
                            productListStyle !== '1' && (
                              <Radio
                                // disabled={!isCommonGroupOption(searchCodeType)}
                                value={GROUP_OPTION_COLOR}
                              >
                                Substyle
                              </Radio>
                            )
                          }
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>
                        </Radio.Group>
                      </Row>
                    )
                  }
                  {isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //disabled
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        readOnly
                        ref={codeInputRef}
                        value={searchMultiCode.replace(/\n/g, ', ')}
                      />
                    </StyleInputWrapper>
                  )}
                  {!isCodeListType(searchCodeType) && (
                    <StyleInputWrapper>
                      <Input
                        addonAfter={addonSearchButton()}
                        addonBefore={searchCodeTypeSelector()}
                        allowClear
                        className={filterPanelVisible ? 'code-selector-active' : ''}
                        //onBlur={onSearchbarBlur}
                        onMouseDown={onSearchbarFocus}
                        onChange={onSearchCodeChange}
                        onMouseEnter={onMouseEnterFilter}
                        onMouseLeave={onMouseLeaveFilter}
                        ref={codeInputRef}
                      />
                    </StyleInputWrapper>
                  )}
                </Row>
                {/*totalCount > 0 && <div className="action-btn-wrap"><CSVLink
                  filename="products.csv"
                  data={csvSource(filteredData)}
                  columns={csvColumns}
                  type="default"
                /></div>*/}
              </Row>
              {/*(searchCodeType === CODE_OPTION_SKU_LIST || searchCodeType === CODE_OPTION_COLOR_LIST || searchCodeType === CODE_OPTION_STYLE_LIST || searchCodeType === CODE_OPTION_UPC_LIST) && (
                <Input.TextArea
                  allowClear
                  onChange={onSearchCodeListChange}
                  onKeyDown={onSearchCodeListKeyDown}
                  ref={codeListInputRef}
                  style={{ width: 380, height: 120 }}
                  value={searchMultiCode}
                />
              )*/}
              <Space style={searchMoreStyle}>
                <div className="title-wrapper">
                  <span className="field-label">Title:</span>
                  <Input
                    allowClear
                    onChange={onSearchTitleChange}
                    style={{ width: 218 }}
                  />
                </div>
                <div className="channel-ctrl-flag-wrapper">
                  <span className="field-label">Sales Channel:</span>
                  <ChannelControlFlagSelector
                    onChange={onSelectChannelControlFlags}
                    style={{ width: 226 }}
                  />
                </div>
                <div className="title-wrapper">
                  <span className="field-label">Brand:</span>
                  <Input
                    allowClear
                    className="brand-input"
                    onChange={onSearchBrandChange}
                  />
                </div>
              </Space>
              <Space style={searchMoreStyle}>
                {/*<div className="classification-wrapper">
                  <span className="field-label">Classification:</span>
                  <ClassificationSelector
                    onChange={onSelectClassifications}
                    style={{width: 276}}
                  />
                </div>*/}
                <div className="labels-wrapper">
                  <span className="field-label">Tags</span>
                  <LabelsSelector
                    onChange={onSelectLabels}
                    style={{ width: 285 }}
                  />
                </div>
              </Space>
              <Row align="middle" className="search-btn-row" justify="space-between" style={{ marginTop: searchMore ? 0 : -6, overflowX: 'auto' }}>
                {searchBegun && showSearchConditionTags()}
                {totalCount > 0 && (
                  <div className="action-btn-wrap">
                    <Space>
                      <Button disabled={selectedRows.length === 0 || tempGroup === 3} onClick={()=>{
                        setBulkAssignModalVisible(true)
                      }}>Bulk Assign</Button>
                      <CSVLink
                        filename="products.csv"
                        data={csvSource(filteredData)}
                        columns={csvColumns}
                        type="default"
                      />
                    </Space>

                  </div>
                )}
              </Row>
            </Space>
          </SearchCol>
          {/*<Col>
            <Row align="top" style={{ height: '100%' }}>
              {totalCount > 0 && <CSVLink
                filename="products.csv"
                data={csvSource(filteredData)}
                columns={csvColumns}
                type="default"
              />}
            </Row>
          </Col>*/}
          
        </Row>
        {searched ? (
          <Spacer height={14} />
        ) : (
          SearchTips(680, 300, "Input your search criteria", 130)
        )}
        {
          searched && (
            <div className="content-section" style={{ height: 'calc(100% - 115px)' }}>
              <DataGrid
                idProperty="ProductId"
                rowHeight={35}
                columns={gridColumns(searchTreeMode)}
                dataSource={dataSource}
                defaultFilterValue={filterValue}
                defaultLimit={DEFAULT_GRID_LIMIT}
                //disableLoadingIcon={filterPanelVisible}
                enableFiltering={false}
                filterTypes={gridFilterTypes}
                limit={pageTop}
                loadNode={loadNextLevelProducts}
                //loading={isFetching || filterPanelVisible}
                loading={isFetching}
                onFilterValueChange={onFilterValueChange}
                onLimitChange={onLimitChange}
                onSelectionChange={(props) =>
                  onSelectionChange(
                    props,
                    filteredData,
                    setSelected,
                    setSelectedRows,
                    "ProductId"
                  )
                }
                onSkipChange={onSkipChange}
                onSortInfoChange={onSortChange}
                pageSizes={[20, 30, 50, 100, 200]}
                pagination
                //renderRowContextMenu={renderRowContextMenu}
                renderRowDetails={renderRowDetails}
                rowExpandColumn={searchGroupMode}
                rowExpandHeight={300}
                scrollProps={{ autoHide: false, }}
                selected={selected}
                skip={pageSkip}
                sortable={false}
                style={{ height: '100%' }}
                treeColumn={searchTreeMode ? 'mediaURL' : undefined}
                checkboxColumn
                checkboxOnlyRowSelect
              />
            </div>
          )
        }
      </SiteContent>
    </ContentLayout>
    <SearchFilterPanel
      channelFlagVersion={channelFlagVersion}
      clearChannelFlags={clearChannelFlags}
      clearExtraFilter={() => {
        clearExtraFilters();
        setSearchGroups([]);
        setSearchGroupsStr([]);
        setSearchTags([]);
        setSearchTagVersion(searchTagVersion + 1);
      }}
      clearLabels={clearLabels}
      clearSearchBrand={clearSearchBrand}
      clearSearchTitle={clearSearchTitle}
      excludeSalesChannel={excludeSalesChannel()}
      extraFilter={searchPanelFilters()}
      //extraSorter={[null, null]}
      groupList={groupList}
      handleSearch={handleSearchProducts}
      hidePanel={hideSearchFilterPanel}
      labelVersion={labelVersion}
      onGroupChange={onSelectGroups}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      onTagChange={(value: string) => onSelectTags(value || "")}
      searchBrandVersion={searchBrandVersion}
      searchCodeType={searchCodeType}
      searchGroupsVersion={searchGroupsVersion}
      searchMultiCode={searchMultiCode}
      searchTitleVersion={searchTitleVersion}
      searchTagVersion={searchTagVersion}
      setBrand={setSearchBrand}
      setLabels={setSearchLabels}
      setMultiCode={setSearchMultiCode}
      setSalesChannel={setSearchCCFs}
      setSortType={setSearchSorter}
      setStatus={setSearchStatus}
      setTitle={setSearchTitle}
      showPanel={showSearchFilterPanel}
      status={searchStatus}
      statusOptions={getOperationStatusOptions().map(e => ({ label: e.description, value: e.code }))}
      x={filterPanelOutline.x || 0}
      y={filterPanelOutline.y || 0}
      visible={filterPanelVisible}
      width={filterPanelOutline.width || 0}
      setConditionList={(list: ConditionItem[]) => setConditionList([...list])}
      needAddNewCondition={true}
    />
    {detailVisible && (
      <ProductDetailDialog
        onClose={closeProductDetailDialog}
        product={currentProduct}
        visible={detailVisible}
      />
    )}
    {
      bulkAssignModalVisible && (
        <BulkAssignModal
          onClose={()=> setBulkAssignModalVisible(false)}
          onSuccess={()=>setBulkAssignModalVisible(false)}
          visible
          selectedProductRows={selectedRows}
          group={tempGroup}
        />
      )
    }
  </>);
};

export default PageContent;
