import { notification } from 'antd';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { reportError } from '../components/common/notification';
//import { DEFAULT_ERR_MSG_DISPLAY_DURATION } from '../constants/config';
//import { getProfilesState } from '../redux/utils';
import errorMessage from '../redux/actions/errorMessage';
// eslint-disable-next-line
import store from '../redux/store/index';
// eslint-disable-next-line
import {
  ENTRANCE_B2C,
  ENTRANCE_PORTAL,
  getEntrance,
  getProfile,
  getToken,
  getTokenExpiredDate,
} from '../util/authenticate';

interface AxiosConfig extends AxiosRequestConfig {
  url: string;
}

interface RequestMap {
  [key: string]: any;
}

interface ErrorObject {
  id: string;
  code?: string;
  status: string;
  title: string;
  detail?: string;
  source?: any;
  meta?: any;
  links?: any;
}

// eslint-disable-next-line
class ServiceError extends Error {
  error: ErrorObject;


  constructor(message: string, error: ErrorObject) {
    super(message);
    this.error = error;
  }
}

const authInstance = axios.create({
  baseURL: process.env.AUTH_API_BASE_URL,
});

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
});

const digitSimpleInstance = axios.create({
  baseURL: process.env.BASE_URL,
});

const dropshipInstance = axios.create({
  baseURL: process.env.DROPSHIP_API_BASE_URL,
});

const erpInstance = axios.create({
  baseURL: process.env.ERP_API_BASE_URL,
});

const ccInstance = axios.create({
  baseURL: process.env.CC_API_BASE_URL,
});

const performanceReportInstance = axios.create({
  baseURL: process.env.PFMR_API_BASE_URL,
});

const productMediaFileInstance = axios.create({
  baseURL: process.env.PRODUCT_MEDIA_FILE_URL,
});

const settingInstance = axios.create({
  baseURL: process.env.SETTING_BASE_URL,
});

const commInstance = axios.create({
  baseURL: process.env.COMMUNICATION_URL,
});

const ccChannelMetaInstance = axios.create({
  baseURL: process.env.CC_CHANNEL_META_DATA_URL,
});

const getAccountNum = () => {
  const entrance = getEntrance();
  //const { profiles, selectedIndex } = getProfilesState();
  const { profiles, selectedIndex }: {profiles: any[], selectedIndex: any} = getProfile();
  let [masterAccountNum, profileNum] = [10001, 10001];

  //console.log(profiles, selectedIndex, '<--');
  if (profiles.length > 0 && profiles[selectedIndex]) {
    //masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    masterAccountNum = entrance === ENTRANCE_PORTAL ? profiles[selectedIndex].masterAccountNum : profiles[selectedIndex].MasterAccountNum;
    //profileNum = profiles[selectedIndex].ProfileNum;
    profileNum = entrance === ENTRANCE_PORTAL ? profiles[selectedIndex].profileNum : profiles[selectedIndex].ProfileNum;

    if (masterAccountNum === undefined) masterAccountNum = 10001;
    if (profileNum === undefined) profileNum = 10001;
  }

  return { masterAccountNum, profileNum };
};

const onAuthRequest = async (value: AxiosRequestConfig) => {
  const config: AxiosConfig = { url: '', ...value };
  const params: any = {
    code: process.env.AUTH_API_CODE,
  };
  const { masterAccountNum, profileNum } = getAccountNum();

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;
  //console.log('rc', process.env.AUTH_API_BASE_URL, originalRequest);

  return Promise.resolve(originalRequest);
};

const onDropshipRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  //const { profiles, selectedIndex } = getProfilesState();
  const newConfig: AxiosRequestConfig = {
    ...config,
    params: {
      code: process.env.DROPSHIP_API_CODE,
    },
  };
  const { masterAccountNum, profileNum } = getAccountNum();

  newConfig.params.masterAccountNum = masterAccountNum;
  newConfig.params.profileNum = profileNum;
  /*if (profiles.length) {
    newConfig.params.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    newConfig.params.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    newConfig.params.masterAccountNum = 10001;
    newConfig.params.profileNum = 10001;
  }*/

  return newConfig;
};

const onDropshipRequestError = async (error: AxiosError): Promise<AxiosError> => error;

dropshipInstance.interceptors.request.use(onDropshipRequest, onDropshipRequestError);

const onERPRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  //const { profiles, selectedIndex = 0 } = getProfilesState();
  const { masterAccountNum, profileNum } = getAccountNum();
  const newConfig: AxiosRequestConfig = {
    ...config,
    params: {
      code: process.env.ERP_API_CODE,
    },
  };

  //newConfig.headers.common.Authorization = localStorage.getItem('Authorization');
  newConfig.headers.common.Authorization = getToken();
  newConfig.headers.masterAccountNum = masterAccountNum;
  newConfig.headers.profileNum = profileNum;

  /*if (getProfilesState().profiles.length) {
    newConfig.headers.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    newConfig.headers.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    newConfig.headers.masterAccountNum = 10001;
    newConfig.headers.profileNum = 10001;
  }*/

  return newConfig;
};

const onERPRequestError = async (error: AxiosError): Promise<AxiosError> => error;

erpInstance.interceptors.request.use(onERPRequest, onERPRequestError);

const currentExecutingRequests: RequestMap = {};

/**
 * Inspects the object in search of an error object.
 *
 * If an error is found, it'll push a notification to inform the user and will yield a rejected promise.
 *
 * @param response
 */
// eslint-disable-next-line
function errorHandler(response: any) {
  //console.log('error hdl', response);
  if (!response || !response.error) {
    return response;
  }

  let { error }: { error: string | ErrorObject } = response;

  if (typeof error === 'string') {
    error = {
      id: String(new Date().getTime()),
      code: 'UNKNOWN',
      status: 'ERR',
      title: JSON.stringify(error),
      detail: 'There was an error on the API',
    };
  }

  const message = error.title || 'There was an error on the API';
  // console.log('->', response, message);
  // maybe should not show message
  // notification.error({ message, duration: DEFAULT_ERR_MSG_DISPLAY_DURATION });
  // notification.error({ message: 'error error' });

  return Promise.reject(new ServiceError(message, error));
  // throw response;
}

// eslint-disable-next-line
const handleError = async (res: any) => {
  if (res && typeof res === 'object' && res.isAxiosError) {
    const { response } = res;

    if (response) {
      console.log('res ->', response);
    }
  }
};

async function requestSuccess(value: AxiosRequestConfig) {
  //const { profiles, selectedIndex } = getProfilesState();
  const config: AxiosConfig = { url: '', ...value };
  const params: any = {
    code: process.env.CODE,
  };
  const { masterAccountNum, profileNum } = getAccountNum();
  //console.log(getProfile(), masterAccountNum, profileNum);

  /*if (profiles.length) {
    params.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    params.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    params.masterAccountNum = 10001;
    params.profileNum = 10001;
  }*/

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;

  // if (currentExecutingRequests[config.url]) {
  //   const source = currentExecutingRequests[config.url];
  //   delete currentExecutingRequests[config.url];
  //   source.cancel();
  // }

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;

  return Promise.resolve(originalRequest);
}

function requestError(error: any) {
  throw error;
}

function responseSuccess(response: AxiosResponse) {
  // eslint-disable-next-line
  // console.log('rrr->', response);
  if (currentExecutingRequests[response.request.responseURL]) {
    delete currentExecutingRequests[response.request.responseURL];
  }

  if (response?.data?.httpStatusCode && (response?.data?.httpStatusCode !== 200 || response?.data?.httpStatusCode !== 201)) {
    // notification.error({ message: response.data.message });
    throw response.data.message;
  }

  return errorHandler(response.data);
}

function responseError(error: any) {
  const { config } = error;
  const originalRequest = config;
  let errorHandled = false;

  if (axios.isCancel(error)) {
    return new Promise(() => {
    });
  }

  if (currentExecutingRequests[originalRequest.url]) {
    delete currentExecutingRequests[originalRequest.url];
  }

  console.log('htp error', error, Object.keys(error));
  if (!error.response) {
    notification.error({ message: `${error}` });
    throw error;
  }

  if (error.response) {
    console.log('rk', Object.keys(error.response), error.response);
    if ([302, 401, 403].indexOf(error.response.status) > -1) {
      const expire = getTokenExpiredDate() * 1000;
      const now = (new Date()).getTime();

      if (now >= expire) {
        if (getEntrance() === ENTRANCE_B2C) {
          window.location.reload();
        }
      }
    }
  }
  
  if (localStorage.getItem('PopUpExceptionMessage') === '0' && error.request.status === 500) {
    store.dispatch(
      errorMessage.pushMessage({
        id: uuid(),
        url: error.request.responseURL,
        statusCode: error.request.status,
        method: error.response.config.method,
        time: moment().format(),
        // message: typeof(error.response?.data?.message || error.response?.data?.error) !== 'string' ? JSON.stringify(error.response?.data?.message || error.response?.data?.error) : '',
        isRead: false,
        response: error.request.responseText,
      }),
    );
    throw error;
  } else {

    if (error.response.data && error.response.data.message !== 'Profile email not fund.') {
      //const errorMessage = error.response ? error.response.data.message : 'Something went wrong';
      //console.log('error--->', error.response, ':', error.config);
      //notification.error({ message: errorMessage });
      tryReportError(error);
      // eslint-disable-next-line
      errorHandled = true;
    }
  
    //if (error.response.data) {
    if (!errorHandled && error.response.data) {
      //console.log('--->', Object.keys(error), error.isAxiosError, '<--');
      //handleError(error);
      return errorHandler(error.response.data);
      // errorHandler(error.response.data);
    }
  
    throw error;
  }


}



async function requestPFMRSuccess(value: AxiosRequestConfig) {
  const config: AxiosConfig = { url: '', ...value };
  const params: any = {
    code: process.env.PFMR_API_CODE,
  };
  const { masterAccountNum, profileNum } = getAccountNum();

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;
  // if (currentExecutingRequests[config.url]) {
  //   const source = currentExecutingRequests[config.url];
  //   delete currentExecutingRequests[config.url];
  //   source.cancel();
  // }

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;

  return Promise.resolve(originalRequest);
}

async function requestPMFSuccess(value: AxiosRequestConfig) {
  //const { profiles, selectedIndex } = getProfilesState();
  const { masterAccountNum, profileNum } = getAccountNum();
  const config: AxiosConfig = { url: '', ...value };

  const params: any = {
    code: process.env.PRODUCT_MEDIA_FILE_CODE,
  };

  params.masterAccountNum = masterAccountNum;
  params.profileNum = profileNum;
  /*if (profiles.length) {
    params.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    params.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    params.masterAccountNum = 10001;
    params.profileNum = 10001;
  }*/

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = params.masterAccountNum;
  config.headers.common.profileNum = params.profileNum;

  return Promise.resolve(originalRequest);
}

async function requestSettingSuccess(value: AxiosRequestConfig) {
  //const { profiles, selectedIndex } = getProfilesState();
  const config: AxiosConfig = { url: '', ...value };
  const params: any = {
    code: process.env.SETTING_CODE,
  };
  const { masterAccountNum, profileNum } = getAccountNum();

  /*if (profiles.length) {
    params.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    params.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    params.masterAccountNum = 10001;
    params.profileNum = 10001;
  }*/

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;

  // if (currentExecutingRequests[config.url]) {
  //   const source = currentExecutingRequests[config.url];
  //   delete currentExecutingRequests[config.url];
  //   source.cancel();
  // }

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;

  return Promise.resolve(originalRequest);
}

async function requestCommunicationSuccess(value: AxiosRequestConfig) {
  const config: AxiosConfig = { url: '', ...value };
  const params: any = {
    code: process.env.COMMUNICATION_CODE,
  };
  const { masterAccountNum, profileNum } = getAccountNum();

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;

  return Promise.resolve(originalRequest);
}

async function requestCCChannelMetaSuccess(value: AxiosRequestConfig) {
  const config: AxiosConfig = { url: '', ...value };
  const { masterAccountNum, profileNum } = getAccountNum();
  const params: any = {
    code: process.env.CC_CHANNEL_META_DATA_CODE,
    masterAccountNum,
    profileNum,
  };

  config.params = {
    ...config.params,
    ...params,
  };

  const originalRequest = config;
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  originalRequest.cancelToken = source.token;
  currentExecutingRequests[config.url] = source;

  //config.headers.common.Authorization = localStorage.getItem('Authorization');
  config.headers.common.Authorization = getToken();
  config.headers.common.masterAccountNum = masterAccountNum;
  config.headers.common.profileNum = profileNum;

  return Promise.resolve(originalRequest);
}


function tryReportError(err: StringKAnyVPair) {
  //console.log('error-->', Object.keys(err), err);
  if (err.isAxiosError) {
    // when httpCode === 400, show error message
    if (err.response.status === 400) {
      const errMessage = err.response?.data?.message || err.response?.data?.error;

      return notification.error({
        message: 'Bad Request',
        description: typeof errMessage === 'string' ? errMessage : 'Something went wrong',
      });
    }
    //const errorMessage = error.response ? error.response.data.message : 'Something went wrong';
    //console.log(err.config, err.response, err.request);
    const errObj = {
      config: err.config,
      response: err.response,
      request: {
        responseText: err.request.response,
        responseURL: err.request.responseURL,
        status: err.request.status,
        statusText: err.request.statusText,
        timeout: err.request.timeout,
      },
    };
    reportError(errObj);
  }
}

const onCCRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const newConfig: AxiosRequestConfig = {
    ...config,
    params: {
      ...config.params,
      code: process.env.CC_API_CODE,
    },
  };

  //const { profiles, selectedIndex = 0 } = getProfilesState();
  const { masterAccountNum, profileNum } = getAccountNum();

  newConfig.headers.Authorization = getToken();
  newConfig.headers.masterAccountNum = masterAccountNum;
  newConfig.headers.profileNum = profileNum;

  /*if (getProfilesState().profiles.length) {
    newConfig.headers.Authorization = getToken();
    newConfig.headers.masterAccountNum = profiles[selectedIndex].MasterAccountNum;
    newConfig.headers.profileNum = profiles[selectedIndex].ProfileNum;
  } else {
    newConfig.headers.Authorization = getToken();
    newConfig.headers.masterAccountNum = 10001;
    newConfig.headers.profileNum = 10001;
  }*/

  return newConfig;
};

function ccResponseSuccess(response: AxiosResponse) {
  return response.data;
}

function ccRequestError(error: any) {
  throw error;
}

function ccResponsetError(error: any) {
  throw error;
}

ccInstance.interceptors.request.use(onCCRequest, ccRequestError);
ccInstance.interceptors.response.use(ccResponseSuccess, ccResponsetError);

performanceReportInstance.interceptors.request.use(requestPFMRSuccess, requestError);
performanceReportInstance.interceptors.response.use(responseSuccess, responseError);

productMediaFileInstance.interceptors.request.use(requestPMFSuccess, requestError);
productMediaFileInstance.interceptors.response.use(responseSuccess, responseError);

settingInstance.interceptors.request.use(requestSettingSuccess, requestError);
settingInstance.interceptors.response.use(responseSuccess, responseError);

commInstance.interceptors.request.use(requestCommunicationSuccess, requestError);
commInstance.interceptors.response.use(responseSuccess, responseError);

ccChannelMetaInstance.interceptors.request.use(requestCCChannelMetaSuccess, requestError);
ccChannelMetaInstance.interceptors.response.use(responseSuccess, responseError);

authInstance.interceptors.request.use(onAuthRequest, requestError);
authInstance.interceptors.response.use(responseSuccess, responseError);

axiosInstance.interceptors.request.use(requestSuccess, requestError);
axiosInstance.interceptors.response.use(responseSuccess, responseError);
digitSimpleInstance.interceptors.request.use(requestSuccess, requestError);

//export const getToken = () => localStorage.getItem('Authorization') || '';

export const isRequestError = (e: any) => {
  return !!e && typeof e === 'object' && !!e.isAxiosError;
};

export const validateStatus = (sl: number[]) => (s: number) => [200, ...sl].indexOf(s) > -1;

export const authAPI = authInstance;
export const digitBridgeApi = axiosInstance;
export const digitBridgeSimpleApi = digitSimpleInstance;
export const dropshipAPI = dropshipInstance;
export const erpAPI = erpInstance;
export const cwAPI = ccInstance;
export const performanceReportAPI = performanceReportInstance;
export const pmfAPI = productMediaFileInstance;
export const settingAPI = settingInstance;
export const commAPI = commInstance;
export const ccChannelMetaApi = ccChannelMetaInstance;
export default axiosInstance;
