import React from 'react';

import AdminSVG from '../assets/icons/Admin_bg';
import CCSVG from '../assets/icons/CC_bg';
import CustomerPortalBGIcon from '../assets/icons/CustomerPortal_bg';
import DSCSVG from '../assets/icons/DSC_bg';
import DSERPSVG from '../assets/icons/DSERP_bg';
import DSMARKETSVG from '../assets/icons/DS_MARKETPLACE_bg';
import DSVPSVG from '../assets/icons/DS_VP_bg';
import ERPSVG from '../assets/icons/ERP_bg';
import PIMSVG from '../assets/icons/PIM_bg';
import POSSVG from '../assets/icons/POS_bg';
import T3PLERPSVG from '../assets/icons/T3PL_ERP_bg';
import T3PLCPSVG from '../assets/icons/T3PL_CP_bg';
import WMSSVG from '../assets/icons/WMS_bg';

// eslint-disable-next-line
export const appLinkCfg: StringKAnyVPair = {
  'ADMIN-DB': {
    id: 'ADMIN-DB',
    group: 'yourApps',
    icon: <AdminSVG />,
    name: 'Admin',
    summary: 'Manage tools and information related to system, network, and applications',
    url: process.env.ADMIN_LINK,
  },
  'CC-DB': {
    id: 'CC-DB',
    group: 'yourApps',
    icon: <CCSVG />,
    name: 'CC',
    summary: 'Manage your channel integrations and keep track of sales performance',
    url: (process.env.MODE || '') === 'admin' ? process.env.CMC_LINK : window.location.origin,
  },
  'DS_CENTRAL-DB': {
    id: 'DS_CENTRAL-DB',
    group: 'yourApps',
    icon: <DSCSVG />,
    name: 'DSC',
    summary: 'Suppliers, vendors, and retailers connect to communicate and share product information',
    url: process.env.DSC_LINK,
  },
  'DS_ERP-DB': {
    id: 'DS_ERP-DB',
    group: 'yourApps',
    icon: <DSERPSVG />,
    name: 'Dropshipper ERP',
    summary: 'The operational prowess for dropship suppliers and dropship retailers',
    url: process.env.DS_ERP_LINK,
  },
  'DS_MARKETPLACE-DB': {
    id: 'DS_MARKETPLACE-DB',
    group: 'yourApps',
    icon: <DSMARKETSVG />,
    name: 'DS Marketplace',
    summary: 'Dropship Marketplace',
    url: process.env.DS_MP_LINK,
  },
  'ERP-DB': {
    id: 'ERP-DB',
    group: 'yourApps',
    icon: <ERPSVG />,
    name: 'ERP',
    summary: 'Manage your business operations: Sales, Purchasing, Inventory, and more',
    url: process.env.ERP_LINK,
  },
  'PIM-DB': {
    id: 'PIM-DB',
    group: 'yourApps',
    icon: <PIMSVG />,
    name: 'PIM',
    summary: 'Manage and distribute product information across different channels',
    url: (process.env.MODE || '') === 'admin' ? process.env.PIM_LINK : `${window.location.origin}/pim/dashboard`,
  },
  'POS-DB': {
    id: 'POS-DB',
    group: 'yourApps',
    icon: <POSSVG />,
    name: 'POS',
    summary: 'Process sales and transactions and sync with your inventory',
    url: process.env.POS_LINK,
  },
  'THREE_PL_ERP-DB': {
    id: 'THREE_PL_ERP-DB',
    group: 'yourApps',
    icon: <T3PLERPSVG />,
    name: '3PL ERP',
    summary: 'Full 3PL management for supply chain and logistics',
    url: process.env.T3PL_ERP_LINK,
  },
  'WMS-DB': {
    id: 'WMS-DB',
    group: 'yourApps',
    icon: <WMSSVG />,
    name: 'WMS',
    summary: 'Warehouse management - pick, pack, ship, and receive orders',
    url: process.env.WMS_LINK,
  },
  'CUSTOMER_PORTAL-DB': {
    id: 'CUSTOMER_PORTAL-DB',
    group: 'customerPortal',
    icon: <CustomerPortalBGIcon />,
    name: 'Customer Portal',
    summary: 'Access for B2B customers to view your product catalog and make orders',
    url: process.env.CUSTOMER_PORTAL_LINK,
  },
  'THREE_PL_CLIENT_PORTAL-DB': {
    id: 'THREE_PL_CLIENT_PORTAL-DB',
    group: 'customerPortal',
    icon: <T3PLCPSVG />,
    name: '3PL Client Portal',
    summary: 'Enterprise Resource Planning',
    url: process.env.T3PL_CP_LINK,
  },
  'DS_VENDOR_PORTAL-DB': {
    id: 'DS_VENDOR_PORTAL-DB',
    group: 'venderPortal',
    icon: <DSVPSVG />,
    name: 'Vender Portal',
    summary: 'Enterprise Resource Planning',
    url: process.env.DS_VP_LINK,
  },
};
